export const Benefits  = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-benny" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations.html">Prestations<span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/ae.html">Prestations
                    d'assurance-emploi et congés</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/famille.html">Prestations
                    pour les familles et les proches aidants</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/pensionspubliques.html">Pensions
                    publiques</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/etudes.html">Aide
                    financière aux étudiants et planification des études</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/logement.html">Prestations
                    relatives au logement</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/handicap.html">Prestations
                    d’invalidité</a></li>

                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/clientele.html">Prestations
                    par clientèle</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/calendrier.html">Dates de
                    paiement des prestations</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://benefitsfinder.services.gc.ca/hm?GoCTemplateCulture=fr-CA&amp;cl=true">Chercheur
                    de prestations</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/prestations/aviser-gouvernement-deces.html">Aviser
                    le gouvernement d’un décès</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-benny-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-benny-sub" role="menu" aria-orientation="vertical">

                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/prestations/ae/assurance-emploi-reguliere.html">Présenter
                            une demande d’assurance-emploi</a></li>

                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/prestations/education/aide-etudiants/bourses-prets.html">Faire
                            une demande de bourses et de prêts d’études</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/gouvernement/ouvrir-session-dossier-compte-en-ligne.html">Ouvrir
                            une session pour un compte en ligne du gouvernement du Canada</a></li>

                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.tpsgc-pwgsc.gc.ca/recgen/txt/depot-deposit-fra.html">Inscrivez-vous
                            au dépôt direct</a></li>

                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/agence-revenu/services/prestations-enfants-familles/calculateur-prestations-enfants-familles.html">Calculateur
                            de prestations pour enfants et familles</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/prestations/ae/assurance-emploi-declaration-internet.html">Soumettre
                            une déclaration d’assurance-emploi</a></li>

                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-benny" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.canada.ca/en/services/benefits.html">Benefits<span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/ei.html">Employment Insurance
                benefits and leave</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/family.html">Family and caregiving
                benefits</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/publicpensions.html">Public
                pensions</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/education.html">Student aid and
                education planning</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/housing.html">Housing benefits</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/disability.html">Disability
                benefits</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/audience.html">Benefits by
                audience</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/calendar.html">Benefits payment
                dates</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://benefitsfinder.services.gc.ca/hm?GoCTemplateCulture=en-CA">Benefits
                finder</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/benefits/notify-government-death.html">Notify
                the government of a death</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-benny-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-benny-sub" role="menu" aria-orientation="vertical">

                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/benefits/ei/ei-regular-benefit.html">Apply
                        for Employment Insurance</a></li>

                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/benefits/education/student-aid/grants-loans.html">Apply
                        for student loans and grants</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/government/sign-in-online-account.html">Sign
                        in to a Government of Canada online account</a></li>

                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tpsgc-pwgsc.gc.ca/recgen/txt/depot-deposit-eng.html">Sign
                        up for direct deposit</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/benefits/ei/ei-internet-reporting.html">Submit
                        your EI report</a></li>

                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cra-arc.gc.ca/bnfts/clcltr/cfbc-eng.html">Child and family
                        benefits calculators</a></li>
                </ul>
            </li>
        </ul>
    )
}
