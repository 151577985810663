export const ScienceAndInnovation = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-science" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science.html">Science<span
                    className="hidden-xs hidden-sm"> et innovation</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/financementrecherche.html">Financement,
                    subventions et prix pour la recherche</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/themesscientifiques.html">Thèmes
                    scientifiques</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/donnees-ouvertes.html">Données
                    ouvertes, statistiques et archives</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/instituts.html">Instituts et
                    établissements de recherches</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/innovation.html">R-D et
                    innovation</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/pi.html">Propriété
                    intellectuelle et droit d'auteur</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/repertoirescientifiques.html">Répertoire
                    des scientifiques et des professionnels de la recherche</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/ressourcespedagogiques.html">Ressources
                    pédagogiques scientifiques</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-science-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-science-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.nrc-cnrc.gc.ca/fra/publications/centre_codes/2015_code_national_batiment.html">Code
                            national du bâtiment</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.nrc-cnrc.gc.ca/fra/services/heure/horloge_web.html#tzpanel-4">Heures
                            officielles au Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.nrc-cnrc.gc.ca/fra/services/levers/index.html">Trouver
                            les heures de levers et de couchers du soleil</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.nrc-cnrc.gc.ca/fra/pari/services/aide_financiere.html">Bourses
                            pour l’innovation technologique (PARI)</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://science-libraries.canada.ca/fra/accueil/">Bibliothèque
                            scientifique fédérale</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://asc-csa.gc.ca/fra/astronomie/auroramax/hd-480.asp">Aurores
                            boréales en direct</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-science" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science.html">Science<span
                className="hidden-xs hidden-sm"> and innovation</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/researchfunding.html">Research
                funding and awards</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/sciencesubjects.html">Science
                subjects</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/open-data.html">Open data,
                statistics and archives</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/institutes.html">Research
                institutes and facilities</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/innovation.html">R&amp;D and
                innovation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/ip.html">Intellectual property
                and copyright</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/scientistsdirectory.html">Directory
                of scientists and research professionals</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/educationalresources.html">Science
                education resources</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-science-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-science-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.nrc-cnrc.gc.ca/eng/publications/codes_centre/2015_national_building_code.html">National
                        building codes</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.nrc-cnrc.gc.ca/eng/services/time/web_clock.html#tzpanel-4">Official
                        time across Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.nrc-cnrc.gc.ca/eng/services/sunrise/index.html">Check
                        sunrise and sunset times</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.nrc-cnrc.gc.ca/eng/irap/services/financial_assistance.html">Grants
                        for technological innovation (IRAP)</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://science-libraries.canada.ca/eng/home/">Federal Science
                        Library</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://asc-csa.gc.ca/eng/astronomy/auroramax/hd-480.asp">Live view
                        of northern lights cam</a></li>
                </ul>
            </li>
        </ul>
    )
}
