import React from "react";

export const ConditionalRender = ({condition, comp}) => {
    if (condition === true) {
        return comp
    }
    return
};

export default ConditionalRender;
