import React from "react";
import { Route, Routes } from "react-router-dom";
import CancelAppointment from "../Components/CancelAppointment/CancelAppointment.jsx";
import Footer from "../Components/Footer/Footer.jsx";
import Header from "../Components/Header/Header.jsx";
import ConfirmCancelAppointment from "../Components/ConfirmCancelAppointment/ConfirmCancelAppointment.jsx";
import ViewAppointment from "../Components/ViewAppointment/ViewAppointment.jsx";
import EngOrFrench from "../Components/LangSelect/LangSelect";
import Home from "../Containers/Home/Home.jsx";
import Redirect from "../Containers/Redirect/Redirect.jsx";
import ScheduleAppointment from "../Components/ScheduleAppointment/ScheduleAppointment.jsx";
import Unauthorized from "../Components/UnauthorizedPage/Unauthorized.jsx";
import ConfirmScheduledAppointment from "../Components/ConfirmScheduledAppointment/ConfirmScheduledAppointment";
import { routeMappings } from "./UIRoutes";
import GetHelpButton from "../Components/Buttons/GetHelpButton/GetHelpButton.jsx";

const contentPanel = ({ enableErrorPage }) => {
  let routes = null;

  routes = (
    <div>
      <Header
        key={window.location.pathname}
        shouldShow={window.location.pathname !== "/"}
      />
      <GetHelpButton />
      <Routes>
        <Route exact path="/" element={<EngOrFrench />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/view-appointment" element={<ViewAppointment />} />
        <Route exact path="/cancel" element={<CancelAppointment />} />
        <Route
          exact
          path="/cancelled-appointment"
          element={<ConfirmCancelAppointment />}
        />
        <Route
          exact
          path="/appointment-confirmed"
          element={<ConfirmScheduledAppointment />}
        />
        <Route
          exact
          path={routeMappings.rescheduleConfirm}
          element={<ConfirmScheduledAppointment />}
        />
        <Route exact path="/appointments" element={<ScheduleAppointment />} />
        <Route
          exact
          path="/reschedule-appointment"
          element={<ScheduleAppointment />}
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Footer shouldShow={window.location.pathname !== "/"} />
    </div>
  );

  return routes;
};

export default contentPanel;
