import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ScheduleAppointmentButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const scheduleAppointmentHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    navigate("/appointments");
  };

  return (
    <button className="scheduleButton" onClick={scheduleAppointmentHandler}>
      {t("button.scheduleAppointment")}
    </button>
  );
};

export default ScheduleAppointmentButton;
