import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const Loader = ({
  loading,
  loadingLabel,
  content,
  hasError = false,
  errorContent,
  minHeight = "300px",
}) =>
  loading && !hasError ? (
    <>
      <div className={"text-center col-xs-12"} style={{ minHeight }}>
        <div className={"loading"}>
          <FontAwesomeIcon icon={faSpinner} className={"icon_pulse"} />
        </div>
        <div className={"loading-text"}>{loadingLabel}</div>
      </div>
    </>
  ) : !hasError ? (
    content
  ) : (
    errorContent
  );
