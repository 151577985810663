export const BusinessAndIndustry = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-biz" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises.html">Entreprises<span
                    className="hidden-xs hidden-sm"> et industrie</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/lancer.html">Démarrage
                    d'entreprise</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/subventions.html">Subventions
                    et financement pour les entreprises</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/impots.html">Taxes et
                    impôt des entreprises</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/societes-de-regime-federal.html">Sociétés
                    de régime fédéral</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/engager.html">Embauche et
                    gestion de personnel</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/commerce.html">Commerce
                    international et investissements</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/permis.html">Permis,
                    licences et règlements</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/faire-affaire.html">Faire
                    affaire avec le gouvernement</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/science/innovation.html">Recherche-développement
                    et innovation</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/recherche.html">Recherche
                    et renseignements d'affaires</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/pi.html">Propriété
                    intellectuelle et droit d'auteur</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/maintenirfairecroitreameliorerentreprise.html">Administration
                    de votre entreprise</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/proteger.html">Protection
                    de votre entreprise</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/faillites.html">Insolvabilité
                    pour les entreprises</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-biz-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-biz-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/fdrlCrpSrch.html">Trouver
                            une société</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cbsa-asfc.gc.ca/prog/manif/portal-portail-fra.html">Déclarer
                            vos produits importés</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.ic.gc.ca/app/opic-cipo/trdmrks/srch/accueil?lang=fra">Chercher
                            des marques de commerce</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cbsa-asfc.gc.ca/trade-commerce/tariff-tarif/2018/html/tblmod-1-fra.html">Réviser
                            les tarifs des douanes pour l’importation de produits</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.ic.gc.ca/opic-cipo/cpd/eng/introduction.html">Trouver
                            un brevet</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cbsa-asfc.gc.ca/comm-fra.html">Importer et exporter
                            à partir du Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://ic.gc.ca/eic/site/cd-dgc.nsf/fra/h_cs03922.html">Trouver
                            un nom pour votre compagnie</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/hm.html?f=&amp;selectedDirectorUuid=%3BselectedIncorporatorUuid%3D&amp;metricsId=GTM-WQQH22">Apporter
                            des changements à votre société (Centre de dépôt en ligne)</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-biz" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business.html">Business<span
                className="hidden-xs hidden-sm"> and industry</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/start.html">Starting a
                business</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/grants.html">Business grants and
                financing</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/taxes.html">Business taxes</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/federal-corporations.html">Federal
                corporations</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/hire.html">Hiring and managing
                employees</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/trade.html">International trade
                and investment</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/permits.html">Permits, licences
                and regulations</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/doing-business.html">Doing
                business with government</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/science/innovation.html">R&amp;D and
                innovation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/research.html">Research and
                business intelligence</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/ip.html">Intellectual property
                and copyright</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/maintaingrowimprovebusiness.html">Maintaining
                your business</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/protecting.html">Protecting your
                business</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/bankruptcy.html">Insolvency for
                business</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-biz-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-biz-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/fdrlCrpSrch.html?locale=en_CA">Find
                        a corporation</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cbsa-asfc.gc.ca/prog/manif/portal-portail-eng.html">Report
                        your imported goods</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.ic.gc.ca/app/opic-cipo/trdmrks/srch/home?lang=eng">Search
                        for trademarks</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cbsa-asfc.gc.ca/trade-commerce/tariff-tarif/2018/html/tblmod-1-eng.html">Review
                        custom tariffs for importing goods</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.ic.gc.ca/opic-cipo/cpd/eng/introduction.html">Find a
                        patent</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cbsa-asfc.gc.ca/comm-eng.html">Import and export from
                        Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://ic.gc.ca/eic/site/cd-dgc.nsf/eng/h_cs03922.html">Name a
                        business</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/hm.html?locale=en_CA">Make
                        changes to your corporation (Online Filing Centre)</a></li>
                </ul>
            </li>
        </ul>
    )
}
