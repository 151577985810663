import "./Footer.css";
import { useLocation } from "react-router-dom";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const Footer = ({ shouldShow }) => {
  const { t } = useTranslation();
  const canURL = "https://www.canada.ca";
  const getLocal = localStorage.getItem("locale");

  const location = useLocation();
  if (location.pathname === "/") {
    return;
  }
  return (
    <footer id="wb-info" style={{ zIndex: 1 }}>
      <h2 className="wb-inv">About this site</h2>
      <div className="gc-main-footer">
        <div className="container">
          <nav>
            <h3> {t("GovernmentOfCanadaFooter.title")}</h3>
            <ul className="list-unstyled colcount-sm-2 colcount-md-3">
              <li>
                <a href={`${canURL}/${getLocal}/contact.html`}>
                  {t("GovernmentOfCanadaFooter.contact")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/government/dept.html`}>
                  {t("GovernmentOfCanadaFooter.department")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/government/system.html`}>
                  {t("GovernmentOfCanadaFooter.about")}
                </a>
              </li>
            </ul>
            <h4>
              <span className="wb-inv">Themes and topics</span>
            </h4>
            <ul className="list-unstyled colcount-sm-2 colcount-md-3">
              <li>
                <a href={`${canURL}/${getLocal}/services/jobs.html`}>
                  {t("GovernmentOfCanadaFooter.jobs")}
                </a>
              </li>
              <li>
                <a
                  href={`${canURL}/${getLocal}/services/immigration-citizenship.html`}
                >
                  {t("GovernmentOfCanadaFooter.immigration")}
                </a>
              </li>
              <li>
                <a href="https://travel.gc.ca/">
                  {t("GovernmentOfCanadaFooter.travel")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/business.html`}>
                  {t("GovernmentOfCanadaFooter.business")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/benefits.html`}>
                  {t("GovernmentOfCanadaFooter.benefits")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/health.html`}>
                  {t("GovernmentOfCanadaFooter.health")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/taxes.html`}>
                  {t("GovernmentOfCanadaFooter.taxes")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/environment.html`}>
                  {t("GovernmentOfCanadaFooter.enviroment")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/defence.html`}>
                  {t("GovernmentOfCanadaFooter.nationalSecurity")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/culture.html`}>
                  {t("GovernmentOfCanadaFooter.culture")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/policing.html`}>
                  {t("GovernmentOfCanadaFooter.policing")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/transport.html`}>
                  {t("GovernmentOfCanadaFooter.transport")}
                </a>
              </li>
              <li>
                <a href="https://international.gc.ca/world-monde/index.aspx?lang=eng">
                  {t("GovernmentOfCanadaFooter.canadaAndWorld")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/finance.html`}>
                  {t("GovernmentOfCanadaFooter.money")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/science.html`}>
                  {t("GovernmentOfCanadaFooter.science")}
                </a>
              </li>
              <li>
                <a
                  href={`${canURL}/${getLocal}/services/indigenous-peoples.html`}
                >
                  {t("GovernmentOfCanadaFooter.indigenous")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/veterans.html`}>
                  {t("GovernmentOfCanadaFooter.veterans")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/services/youth.html`}>
                  {t("GovernmentOfCanadaFooter.youth")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="gc-sub-footer">
        <div className="container d-flex align-items-center">
          <nav>
            <h3 className="wb-inv">Government of Canada Corporate</h3>
            <ul>
              <li>
                <a href="https://www.canada.ca/${getLocal}/social.html">
                  {t("GovernmentOfCanadaFooter.social")}
                </a>
              </li>
              <li>
                <a href="https://www.canada.ca/${getLocal}/mobile.html">
                  {t("GovernmentOfCanadaFooter.mobile")}
                </a>
              </li>
              <li>
                <a href="https://www.canada.ca/${getLocal}/government/about.html">
                  {t("GovernmentOfCanadaFooter.aboutCanada")}
                </a>
              </li>

              <li>
                <a href={`${canURL}/${getLocal}/transparency/terms.html`}>
                  {" "}
                  {t("GovernmentOfCanadaFooter.terms")}
                </a>
              </li>
              <li>
                <a href={`${canURL}/${getLocal}/transparency/privacy.html`}>
                  {" "}
                  {t("GovernmentOfCanadaFooter.privacy")}
                </a>
              </li>
            </ul>
          </nav>
          <div className="wtrmrk align-self-end">
            <img
              src="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/wmms-blk.svg"
              alt="Symbol of the Government of Canada"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
