import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { authenticateToken } from "../../util/ApiCalls";
import ReturnTTPButton from "../Buttons/ReturnTTPButton/ReturnTTPButton";
import "./Unauthorized.css";

const Unauthorized = () => {
  const [response, setResponse] = useState();

  const { t } = useTranslation();

  // messages based off of the error
  const errorInfo = {
    401: t("unauthorized.401"),
    404: t("unauthorized.404"),
    500: t("unauthorized.500"),
  };

  /**
   * Call the backend to verify the authorization token
   * use the status of the token to determine the error message
   * @returns {Promise<void>}
   */
  const checkTokenAuthorizations = async () => {
    try {
      const resultOfToken = await authenticateToken();
      setResponse(resultOfToken.status);
    } catch (err) {
      setResponse(500);
    }
  };

  useEffect(() => {
    checkTokenAuthorizations();
  }, []);

  return (
    <>
      <div className="homeContainer" id={"wb-cont"}>
        <div className="homeTitleContainer">
          <div class="alert alert-danger"></div>
          <h1 className="auth-title">{t("unauthorized.title")}</h1>
        </div>
        <div className="createSchedulerContainer">
          <div className="confirm-container">
            <p>{errorInfo[response ?? "200"]}</p>
          </div>
        </div>
        <ReturnTTPButton />
      </div>
    </>
  );
};
export default Unauthorized;
