import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RescheduleAppointmentButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rescheduleHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    navigate("/reschedule-appointment");
  };

  return (
    <button className="buttonStyling" onClick={rescheduleHandler}>
      {t("button.reschdule")}
    </button>
  );
};

export default RescheduleAppointmentButton;
