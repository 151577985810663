import { configureStore } from "@reduxjs/toolkit";
import SetPortWorklocations from "./SetPortWorklocations";
import SetUserCredentials from "./SetUserCredentials";
import SetUserLanguage from "./SetUserLanguage";

export const store = configureStore({
  reducer: {
    userCredentials: SetUserCredentials,
    userLocale: SetUserLanguage,
    portWorklocations: SetPortWorklocations,
  },

  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
