import {getApplicationDetails} from "../util/ApiCalls";
import {ConsoleLogger} from "../Logger/Logger";

const logger = new ConsoleLogger();

/**
 * Find the applicant details to display on the page
 * @returns {Promise<void>}
 */
export const findClient = async (setClient, setClientNotFound, setClient500) => {
    try {
        logger.log("Finding the client")
        const applicant = await getApplicationDetails();
        // applicant not found
        setClientNotFound(applicant.status === 404)
        setClient500(applicant.status === 500)
        if (applicant.status === 200) {
            const data = Array.isArray(applicant.data) ? applicant.data[0] : applicant.data
            setClient(data)
            const clientId = data.clientId
            sessionStorage.setItem('gec-client-id', clientId)
        }
    } catch (err) {
        setClient500(true)
    }
}
