import React from "react";
import { useNavigate } from "react-router-dom";
import "./ConfirmCancelAppointment.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import ClientInformation from "../ClientInformation/ClientInformation";

const ConfirmCancelAppointment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const doneHandler = () => {
    
    navigate("/home");
    window.scrollTo({top:10,behavior:'smooth'});
  };

  const rescheduleHandler = () => {
    navigate("/reschedule-appointment");
    window.scrollTo({top:10,behavior:'smooth'});
  };

  return (
    <div id={"wb-cont"} className="mainContainer">
      <div className="homeTitleContainer">
        <h1>{t("confirmCancelApp.title")}</h1>
      </div>
      <p>{t("confirmCancelApp.paragraphOne")}</p>
      <br />
      <div className="viewAppointmentButtonContainer">
        <button className="buttonStyling" onClick={doneHandler}>
          {t("confirmCancelApp.done")}
        </button>
      </div>
      <br />
    </div>
  );
};

export default ConfirmCancelAppointment;
