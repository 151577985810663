// import Cookies from 'universal-cookie';
import React, { useEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import ContentPanel from "./Router/ContentPanel";

import { useDispatch } from "react-redux";

import {
  authenticateToken,
  getApplicationStatus,
  getWorklocationsFromApi,
} from "./util/ApiCalls";

import "./App.css";
import {
  applicationType,
  portWorklocations,
  programType,
} from "./Redux/SetPortWorklocations";
import { ConsoleLogger } from "./Logger/Logger";
import { Loader } from "./Components/Loading/Loader";
import Maintenance from "./Containers/Maintenance/Maintenance";

const logger = new ConsoleLogger(App);

// ENUM
const AllowedStates = {
  AUTHORIZED: 1,
  UNAUTHORIZED: 2,
  UNDEFINED: -1,
};

function App() {
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(AllowedStates.UNDEFINED);
  const [applicationStatus, setApplicationStatus] = useState(false);
  const [applicationStatusText, setApplicationStatusText] = useState({});

  /**
   * Route base path to /ttp
   */
  if (window.location.pathname === "/") {
    window.location.pathname = "/ttp";
  }

  /**
   * Save codes to dispatch
   * @param locations
   */
  const storeCodes = (locations) => {
    dispatch(portWorklocations(locations.data.codes[0].codes));
    dispatch(programType(locations.data.codes[1].codes));
    dispatch(applicationType(locations.data.codes[2].codes));
  };

  /**
   * Default to empty list if failed
   */
  const failStoreCodes = () => {
    dispatch(portWorklocations([]));
    dispatch(programType([]));
    dispatch(applicationType([]));
  };

  /**
   * Get the codes the UI calls
   * @returns {Promise<void>}
   */
  const getCodes = async () => {
    let locationResults = await getWorklocationsFromApi();

    if (locationResults.status === 200) {
      storeCodes(locationResults);
    } else {
      failStoreCodes();
    }
  };

  /**
   * Call the backend to verify the authorization token
   * @returns {Promise<void>}
   */
  const checkTokenAuthorizations = async () => {
    try {
      logger.log("Verifying the user token");
      const resultOfToken = await authenticateToken();
      // setAllowed(resultOfToken.status === GE_RES_CODES.OK)
      logger.log("Verified the user token");
      setAllowed(
        resultOfToken.status === 200
          ? AllowedStates.AUTHORIZED
          : AllowedStates.UNAUTHORIZED
      );
    } catch (err) {
      setAllowed(AllowedStates.UNAUTHORIZED);
    }
  };

  const checkApplicationStatus = async () => {
    //api call here
    let appStatus = await getApplicationStatus();

    if (appStatus) {
      if (appStatus.data?.status === "online") {
        setApplicationStatus(false);
      } else {
        setApplicationStatus(true);
        setApplicationStatusText(appStatus.data.message);
      }
    }
  };

  // useEffect(() => {
  //   checkApplicationStatus();
  // }, []);

  useEffect(() => {
    /**
     * Call the current window or dev1 if it is local
     */
    let token = new URLSearchParams(document.location.search).get("token");
    let redirect = new URLSearchParams(document.location.search).get(
      "redirect"
    );

    // save token locally
    if (token) {
      localStorage.removeItem("myToken");
      localStorage.setItem("myAccessId", token);
    }

    // save redirect
    if (redirect) {
      localStorage.setItem("user-redirect", redirect);
      // cookies.set('user-redirect', redirect);
    }

    // begin auth check
    checkTokenAuthorizations();

    logger.log("Application is starting.");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allowed === AllowedStates.UNDEFINED) {
      // do nothing
    } else if (allowed === AllowedStates.AUTHORIZED) {
      getCodes();
      checkApplicationStatus();
    } else if (allowed === AllowedStates.UNAUTHORIZED) {
      if (window.location.pathname !== "/ttp/unauthorized") {
        window.location.pathname = "/ttp/unauthorized";
      }
    }
  }, [allowed]);

  const contentInRouter = <ContentPanel />;

  // logger.push({})
  return (
    <div className="App">
      {applicationStatus ? (
        <BrowserRouter>
          {<Maintenance applicationStatusText={applicationStatusText} />}
        </BrowserRouter>
      ) : (
        <Loader
          content={
            <BrowserRouter basename={"/ttp"}>{contentInRouter}</BrowserRouter>
          }
          loading={allowed === AllowedStates.UNDEFINED}
        />
      )}
    </div>
  );
}

export default App;
