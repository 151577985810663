import Calendar from "react-calendar";
import StartOverButton from "../../Buttons/StartOverButton/StartOverButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import "./StepTwo.css";
import moment from "moment/moment";
import {
  getDayAvailability,
  getLocationAvailability,
} from "../../../util/ApiCalls";
import {
  dateString,
  dateStringToDate,
  getDate,
  getHour,
} from "../../../Coverters/dateConverters";
import { Loader } from "../../Loading/Loader";
import { findParam } from "../../../util/Function/CodeParametersUtil";

const TimePicker = ({
  date,
  timeString,
  timeRows,
  times,
  selectedTime,
  timeSelectHandler,
}) => {
  // let timesDisplay = <span>404 time not found error </span>
  // if (times.length > 0) {
  //     timesDisplay = times.map((item) => (
  //         <button
  //             className={item.appointment ? "booked bookingBtn" : "notBooked bookingBtn"}
  //             disabled='true'
  //             onClick={
  //                 selectedTime === item.time
  //                     ? () => timeSelectHandler(null)
  //                     : () => timeSelectHandler(item.time)
  //             }
  //             style={{
  //                 background:
  //                     item.time === selectedTime ? "blue" : null,
  //             }}
  //             aria-label={item.time}
  //             tabIndex={item.appointment ? "-1" : "0"}
  //         >
  //             {item.time}
  //         </button>
  //     ))
  // }
  // time slot setup
  const { t } = useTranslation();
  const container = {};
  for (const time of timeRows) {
    container[getHour(time)] = {
      time: getHour(time) + ":00",
      times: [],
    };
  }
  if (times.length > 0) {
    for (const time of times) {
      if (container[getHour(time.time)]) {
        container[getHour(time.time)]?.times.push(time);
      }
    }
  }
  let locale = t("scheduleAppointmenPage.calendarLocale");
  return (
    <div className="step-time-container">
      <div
        style={{
          overflowY: "scroll",
          height: "600px",
          width: "auto",
          flexDirection: "row",
        }}
        className="tableWidth time-table"
      >
        <div className={"flex time-row row row-no-gutters col-xl-12"}>
          <button className={"rowLabelHidden"}>00:00</button>
          <h1
            aria-label={`${getDate(date, locale)}, ${timeString}`}
            tabIndex={"0"}
          >
            {getDate(date, locale)}, {timeString}
          </h1>
        </div>
        {Object.keys(container)
          .sort((k1, k2) => k1.localeCompare(k2))
          .map((key) => {
            const row = container[key];
            let btns = [];
            for (const slot of row.times) {
              // time slot button

              btns.push(
                <button
                  className={
                    slot.appointment
                      ? "booked bookingBtn"
                      : slot.available
                        ? "notBooked bookingBtn"
                        : "notAvailable bookingBtn"
                  }
                  disabled={!slot.available}
                  onClick={
                    selectedTime === slot.time
                      ? () => timeSelectHandler(null)
                      : () => timeSelectHandler(slot.time)
                  }
                  style={{
                    background:
                      slot.time === selectedTime ? "rgb(0,0,0)" : null,
                  }}
                  aria-label={slot.time}
                  tabIndex={slot.appointment ? "-1" : "0"}
                >
                  {slot.time}
                </button>
              );
            }
            return (
              <div
                className={
                  "flex time-row rowButtons row row-no-gutters col-xl-12"
                }
              >
                <button className={"rowLabel"}>{row.time}</button>
                {btns}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const StepTwo = ({
  selectedTime,
  date,
  setDate,
  setSelectedTime,
  nextHandler,
  backHandler,
  wloc,
  requiredTime,
}) => {
  
  const { portWorklocations } = useSelector((state) => state.portWorklocations);

  const [times, setTimes] = useState([]);
  const [openDays, setOpenDays] = useState([]);
  const [noOpenDaysFound, setNoOpenDaysFound] = useState(false);
  const [timeRows, setTimeRows] = useState([]);
  const [timesLoading, setTimesLoading] = useState(true);
  const [nextDate, setNextDate] = useState(new Date());
  const [currentLocation, setCurrentLocation] = useState("");
  const { t } = useTranslation();
  let lang = localStorage.getItem("locale");


  const getTomorrow = async () => {
    // get next available date
    let nextDate = moment();

    let dateStr = nextDate.format().slice(0, 10);

    let availability = await getDayAvailability(wloc.value, dateStr);

    do {
      dateStr = nextDate.add(1, "day").format().slice(0, 10);
      availability = await getDayAvailability(wloc.value, dateStr);
    } while (availability?.data.length < 1);

    if (availability?.data) {
      nextDate = availability.data[0]?.date;
    }
    if (nextDate) {
      setNextDate(new Date(nextDate));
    } else {
      setNextDate(new Date());
    }
  };

  useEffect(() => {
    getTomorrow();
  }, [wloc]);

  function filterByValue(array, canadianPort, isUsa) {
    let returnArray = [];
    if (array) {
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < array[i].parameters.length; j++) {
          if (
            array[i]?.parameters[j]?.value === canadianPort &&
            array[i]?.parameters[j]?.key === isUsa
          ) {
            let results = array[i];

            returnArray.push(results);
          }
        }
      }
    }

    return returnArray;
  }

  const filterWlocByLocale = () => {
    if (portWorklocations.length !== 0) {
      let w = portWorklocations
        ?.map((ele) => {
          return {
            value: ele.id,
            label:
              lang === "en" ? ele.descriptionEnglish : ele.descriptionFrench,
            parameters: ele.parameters,
          };
        })
        .sort((o1, o2) => o1?.label?.localeCompare(o2?.label));

      let filterCanadianPorts = filterByValue(w, "false", "isUSA");

      let currLocation = filterCanadianPorts.filter(
        (item) => item.value === wloc.value
      );

      setCurrentLocation(currLocation[0]);
    }
  };

  useEffect(() => {
    filterWlocByLocale();
  }, [lang]);

  /**
   * @param timeslots {[{locationId, date, time, available}]}
   */
  const processTimeslot = (timeslots) => {
    // const timesList = [];
    if (!Array.isArray(timeslots)) {
      return;
    }

    // sort time slots by time. We are only asking for one day at a time so we only look at the time component.
    timeslots.sort((a, b) => a.time.localeCompare(b.time));

    // If times are in format HH:mm:ss, remove the ":ss"
    timeslots.forEach((ts) => (ts.time = ts.time.slice(0, 5)));

    // for (const slot of timeslots) {
    //     if (slot) {
    //         const timeSplice = slot.split(":");
    //         const time = timeSplice[0] + ":" + timeSplice[1];
    //         timesList.push(time);
    //     }
    // }

    // todo - May 19 - FIX THIS to set if this time slot/location is that of the current appointment.
    setTimes(timeslots.map((time) => ({ ...time, appointment: false })));

    // build the hour
    let uniqueHours = [];
    for (let t of timeslots) {
      let hour = getHour(t.time);
      uniqueHours.push(hour + ":00");
    }
    setTimeRows(uniqueHours);
  };

  const findAvaliabilities = async () => {

    const dateStr = dateString(date);

    setTimesLoading(true);
    const today = new Date();
    const end = new Date();
    end.setDate(today.getDate() + 180);
    let openResults = await getLocationAvailability(
      wloc?.value,
      dateString(today),
      dateString(end)
    );

    setNoOpenDaysFound(openResults?.status === 404 || openResults?.status === 500);
    const todayString = dateString(today);
    let openDayAvailable

    if (openResults?.status === 200) {
      if(openResults.data){

      openDayAvailable = openResults.data
      .filter((day) => day.available === true && day.date !== todayString)
      .map((day) => day.date)

        // setOpenDays(
        //   openResults.data
        //   .filter((day) => day.available === true && day.date !== todayString)
        //   .map((day) => day.date)
        // );
      }
    }

    let dayAvaliability
    if(dateStr !== undefined){
      dayAvaliability = await getDayAvailability(wloc?.value, dateStr);
    }
    
      processTimeslot(dayAvaliability?.data);
      setTimesLoading(false);
      
      if(openDayAvailable?.length === 0){
        setNoOpenDaysFound(openDayAvailable?.length === 0)
      }else{
        setOpenDays(openDayAvailable)
      }
  };

  const getDayTimeSlots = async (wloc, date) => {
    const dateStr = dateString(date);

    if (dateStr === undefined) {
      return;
    }else{
          setTimesLoading(true);
          let dayAvaliability = await getDayAvailability(wloc?.value, dateStr);
         
          if (dayAvaliability?.status !==200) {
            setNoOpenDaysFound(true);
          } else {
           
          processTimeslot(dayAvaliability?.data);
            setTimesLoading(false);
          }
    }

  };

  useEffect(() => {

    findAvaliabilities();


  }, []);

  useEffect(() => {

    getDayTimeSlots(wloc, date);


  }, [date]);

  useEffect(() => {
    if (openDays) {
      findMinOpenDate(openDays);
    }
  }, [openDays]);

  const calendarHandlder = (value) => {
    setDate(value);
  };

  const timeSelectHandler = (value) => {
    setSelectedTime(value);
  };

  const filterTile = ({ activeStartDate, date: dateStr, view }) => {
    let dateFound = false;
    for (const day of openDays) {
      if (dateString(dateStr) === day) {
        dateFound = true;
      }
    }
    return !dateFound;
  };

  /**
   * Find the min date for the work location
   */
  const findMinOpenDate = (openDaysList) => {
    const firstDay = dateStringToDate(openDaysList[0]);

    setDate(firstDay);

  };

  let timeString = t("stepTwo.selectATime");
  if (selectedTime) {
    timeString = selectedTime;
  }

  let content = noOpenDaysFound ? (
    ""
  ) : (
    <Loader
      loading={openDays?.length < 1 || !date}
      content={
        // Main content
        <div>
          {/* Color boxes */}
          <div style={{ marginRight: "20px", textAlign: "right" }}>
            <div className="legend-container">
              <p>{t("stepTwo.grey")}</p>
              <div className="greyBox"></div>
            </div>
            <div className="legend-container">
              <p className="tab-reverse">{t("stepTwo.blue")}</p>
              <div className="blueBox"></div>
            </div>

            {requiredTime ? null : (
              <p className="required-text step-two-error">
                {t("scheduleAppointmenPage.errorRequired")}
              </p>
            )}
          </div>
          <div className="contentContainer">
            <div className="calendarWidth">
              <div className={"wlocLabel"}>
                <h3 className={"bold"}>{currentLocation.label}</h3>
              </div>
              <div tabIndex={0} aria-label={t("stepTwo.selectADate")}>
                <Calendar
                  onChange={calendarHandlder}
                  value={date}
                  defaultValue={date}
                  calendarAriaLabel={"Select Calender"}
                  tileDisabled={filterTile}
                  calendarType={"US"}
                  locale={t("scheduleAppointmenPage.calendarLocale")}
                />
              </div>
            </div>
            <Loader
              loading={timesLoading}
              minHeight={"600px"}
              content={
                <TimePicker
                  date={date}
                  timeRows={timeRows}
                  timeString={selectedTime}
                  times={times}
                  selectedTime={selectedTime}
                  timeSelectHandler={timeSelectHandler}
                />
              }
            />
          </div>
          <div className="stepButtonContainer">
            <button
              className="buttonStyling"
              onClick={() => nextHandler(selectedTime)}
              tabIndex={0}
            // disabled={!selectedTime}
            >
              {t("stepThree.next")}
            </button>
          </div>
        </div>
      }
    />
  );

  return (
    <div id={"wb-cont"} className="mainContainer">
      <div className="homeTitleContainer">
        <h1>{t("stepTwo.title")}</h1>
        <br></br>
        <p>
          {noOpenDaysFound
            ? `${wloc.label} ${t("stepTwo.noWP")}`
            : t("stepTwo.bodyText")}
        </p>
        <p>{t("stepTwo.backText")}</p>
      </div>
      <div className="timeContainer">{content}</div>
      <div className="bottomButtonContainer bottomNavSpacing">
        <button className="startOverBtn" onClick={backHandler}>
          {t("button.back")}
        </button>
        <StartOverButton />
      </div>
    </div>
  );
};

export default StepTwo;
