export const TravelAndTourism = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-travel" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/">Voyage<span
                    className="hidden-xs hidden-sm"> et tourisme</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://voyage.gc.ca/voyager/avertissements">Conseils aux voyageurs et
                    avertissements</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/voyage-covid">COVID-19
                    : voyage, dépistage et frontières</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/visiter-canada.html?outside">Visiter
                    le Canada</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/voyager">Voyager à
                    l’étranger</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/avion">Voyager en
                    avion</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/retour">Retour au
                    Canada</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/passeports-canadiens.html">Passeports
                    et documents de voyage canadiens</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/tourisme-canadien">Attraits
                    touristiques, événements et expériences au Canada</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/assistance">Assistance
                    à l’extérieur du Canada</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://voyage.gc.ca/restez-branches">Restez
                    branchés</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-travel-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-travel-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://voyage.gc.ca/assistance/assistance-d-urgence">Assistance
                            d'urgence à l'étranger</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cic.gc.ca/francais/visiter/visas.asp">Vérifiez si
                            vous avez besoin d’un visa pour voyager au Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/visiter-canada/ave.html">Présentez
                            une demande d’Autorisation de voyage électronique (AVE)</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cbsa-asfc.gc.ca/prog/nexus/application-demande-fra.html">Adhérez
                            à NEXUS</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://voyage.gc.ca/voyager/inscription">Inscrivez-vous comme
                            Canadien à l’étranger</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://voyage.gc.ca/voyager/documents/assurance-voyage">Assurance
                            voyage</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-travel" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/">Travel<span
                className="hidden-xs hidden-sm"> and tourism</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/travelling/advisories">Travel
                advice and advisories</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/travel-covid">COVID-19:
                Travel, testing and borders</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada.html?outside">Visit
                Canada</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/travelling">Travel
                outside Canada</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/air">Air travel</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/returning">Return to
                Canada</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-passports.html">Canadian
                passports and travel documents</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/canadian-tourism">Canadian
                attractions, events and experiences</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/assistance">Assistance
                outside Canada</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://travel.gc.ca/stay-connected">Stay
                connected</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-travel-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-travel-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://travel.gc.ca/assistance/emergency-assistance">Emergency
                        assistance abroad</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cic.gc.ca/english/visit/visas.asp">Find out if you need
                        a visa to travel to Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada/eta.html">Apply
                        for an eTA</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cbsa-asfc.gc.ca/prog/nexus/application-demande-eng.html">Apply
                        for NEXUS</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://travel.gc.ca/travelling/registration">Register as a
                        Canadian abroad</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://travel.gc.ca/travelling/documents/travel-insurance">Travel
                        insurance</a></li>
                </ul>
            </li>
        </ul>
    )
}
