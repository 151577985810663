import {getAppointmentDetails} from "../../util/ApiCalls";

/**
 * Get the client phone numbers
 * @param client
 */
export const getPhoneNumbers = (client) => {
    const list = []
    if (client?.phoneNumbers) {
        for (const phoneNumber of client.phoneNumbers) {
            let phoneStr = ''
            if (phoneNumber?.areaCode) {
                phoneStr = phoneNumber.areaCode
            }
            if (phoneNumber.localNumber) {
                phoneStr += '-' + phoneNumber.localNumber.slice(0,3) + '-' + phoneNumber.localNumber.slice(3)
            }
            list.push(phoneStr)
        }
    }
    return list.join(', ')
}

/**
 * Get the client emails from the list of emails
 * @param client
 */
export const getEmails = (client) => {
    const list = []
    if (client?.emails) {
        for (const e of client.emails) {
            if (e && e !== '') {
                list.push(e)
            }
        }
    }
    return list.join(', ')
}

export const findEnrollmentCenterInformation = (appointment, workLocations) => {
    for (const workLocation of workLocations) {
        if (appointment?.locationId) {
            if (parseInt(workLocation.id) === parseInt(appointment.locationId)) {
                return workLocation
            }
        }
    }
}
/**
 * Find applicant
 * @returns {Promise<void>}
 */
export const findAppointment = async (clientId, setAppointment, setAppointmentNotFound) => {
    try {
        const applicant = await getAppointmentDetails(clientId);
        // applicant not found
        setAppointmentNotFound(applicant.status === 404)
        if (applicant.status === 200) {
            const data = Array.isArray(applicant.data) ? applicant.data[0] : applicant.data;
            setAppointment(data);
        }
    } catch (err) {
        setAppointmentNotFound(true)
    }
}
