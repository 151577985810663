import "./Header.css";
import { Benefits } from "./menuPanels/Benefits";
import { Jobs } from "./menuPanels/Jobs";
import {useLayoutEffect, useState} from "react";
import { ImmigrationAncCitizenship } from "./menuPanels/ImmigrationAncCitizenship";
import { BusinessAndIndustry } from "./menuPanels/BusinessAndIndustry";
import { Health } from "./menuPanels/Health";
import { Taxes } from "./menuPanels/Taxes";
import { EnvironmentAndNaturalResource } from "./menuPanels/EnvironmentAndNaturalResource";
import { NationalSecurityAndDefense } from "./menuPanels/NationalSecurityAndDefense";
import { CultureHistoryAndSport } from "./menuPanels/CultureHistoryAndSport";
import { PolicingJusticeAndEmergency } from "./menuPanels/PolicingJusticeAndEmergency";
import { TransportAndInfrastructure } from "./menuPanels/TransportAndInfrastructure";
import { CanadaAndTheWorld } from "./menuPanels/CanadaAndTheWorld";
import { MoneyAndFinances } from "./menuPanels/MoneyAndFinances";
import { ScienceAndInnovation } from "./menuPanels/ScienceAndInnovation";
import { TravelAndTourism } from "./menuPanels/TravelAndTourism";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import {useLocation} from "react-router-dom";

const MenuItem = ({ label, componet, ulSelected, setUlSelected, id }) => (
  <li role="presentation">
    <a
      href="/#"
      role="menuitem"
      onMouseEnter={() => clickMenuUl(setUlSelected, id)}
      onClick={() => clickMenuUl(setUlSelected, id)}
      aria-expanded={ulSelected === id}
    >
      {label}
    </a>
    {componet}
  </li>
);

/**
 * Click the menu ul
 * @param id
 */
const clickMenuUl = (setUl, id) => {
  setUl(id);
};

const buildConfig = (id, label, locale, component) => ({
  id,
  label,
  locale,
  component: component,
});

const languageChange = () => {
  let userLocaleSettings = localStorage.getItem("locale");
  if (userLocaleSettings === "en") {
    localStorage.setItem("locale", "fr");
    i18n.changeLanguage("fr");
  } else {
    localStorage.setItem("locale", "en");
    i18n.changeLanguage("en");
  }
};

const Header = ({shouldShow}) => {

  const location = useLocation();

  const [ulSelected, setUlSelected] = useState("jobs");
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const currentLocale = localStorage.getItem("locale");

  window.document.title = t('global.documentTitle')

  // config
  const config = [
    buildConfig(
      "jobs",
      t("GovernmentOfCanadaMenu.jobMenu"),
      currentLocale,
      Jobs
    ),
    buildConfig(
      "immigrationAnCitizenship",
      t("GovernmentOfCanadaMenu.immigrationAndCitizenship"),
      currentLocale,
      ImmigrationAncCitizenship
    ),
    buildConfig(
      "travelAndTourism",
      t("GovernmentOfCanadaMenu.travelAndTourism"),
      currentLocale,
      TravelAndTourism
    ),
    buildConfig(
      "business",
      t("GovernmentOfCanadaMenu.business"),
      currentLocale,
      BusinessAndIndustry
    ),
    buildConfig(
      "benefits",
      t("GovernmentOfCanadaMenu.benefits"),
      currentLocale,
      Benefits
    ),
    buildConfig(
      "health",
      t("GovernmentOfCanadaMenu.health"),
      currentLocale,
      Health
    ),
    buildConfig(
      "taxes",
      t("GovernmentOfCanadaMenu.taxes"),
      currentLocale,
      Taxes
    ),
    buildConfig(
      "environment",
      t("GovernmentOfCanadaMenu.environment"),
      currentLocale,
      EnvironmentAndNaturalResource
    ),
    buildConfig(
      "nationalSec",
      t("GovernmentOfCanadaMenu.nationalSec"),
      currentLocale,
      NationalSecurityAndDefense
    ),
    buildConfig(
      "culture",
      t("GovernmentOfCanadaMenu.culture"),
      currentLocale,
      CultureHistoryAndSport
    ),
    buildConfig(
      "policy",
      t("GovernmentOfCanadaMenu.policy"),
      currentLocale,
      PolicingJusticeAndEmergency
    ),
    buildConfig(
      "transport",
      t("GovernmentOfCanadaMenu.transport"),
      currentLocale,
      TransportAndInfrastructure
    ),
    buildConfig(
      "canadaWorld",
      t("GovernmentOfCanadaMenu.canadaWorld"),
      currentLocale,
      CanadaAndTheWorld
    ),
    buildConfig(
      "money",
      t("GovernmentOfCanadaMenu.money"),
      currentLocale,
      MoneyAndFinances
    ),
    buildConfig(
      "science",
      t("GovernmentOfCanadaMenu.science"),
      currentLocale,
      ScienceAndInnovation
    ),
  ];

  let menuItems = [];
  for (const c of config) {
    menuItems.push(
      <MenuItem
        label={c.label}
        componet={
          <c.component en={currentLocale} showing={ulSelected === c.id} />
        }
        id={c.id}
        ulSelected={ulSelected}
        setUlSelected={setUlSelected}
      />
    );
  }

  if (location.pathname === '/') {
    return
  }

  return (
    <div>
      <html class="no-js" lang="en" dir="ltr">
        <head>
          <meta charSet="utf-8" />
          <title>Static header and footer - Bootstrap 3 - Canada.ca</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta name="description" content=" " />
          <link
            href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/favicon.ico"
            rel="icon"
            type="image/x-icon"
          />
          <link
            rel="stylesheet"
            href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/css/theme.min.css"
          />
          {/*<noscript>*/}
          {/*    <link*/}
          {/*        rel="stylesheet"*/}
          {/*        href="https://wet-boew.github.io/themes-dist/GCWeb/wet-boew/css/noscript.min.css"*/}
          {/*    />*/}
          {/*</noscript>*/}
        </head>
        <body vocab="https://schema.org/" typeof="WebPage">
          <nav>
            <ul id="wb-tphp">
              <li class="wb-slc">
                <a class="wb-sl" href="#wb-cont">
                  Skip to main content
                </a>
              </li>
              <li class="wb-slc visible-sm visible-md visible-lg">
                <a class="wb-sl" href="#wb-info">
                  Skip to About this site
                </a>
              </li>
            </ul>
          </nav>

          <header>
            <div id="wb-bnr" class="container">
              <div class="row">
                <section
                  id="wb-lng"
                  class="col-xs-3 col-sm-12 pull-right text-right"
                >
                  <h2 class="wb-inv">
                    t('GovernmentOfCanadaMenu.immigrationAndCitizenship')
                  </h2>
                  <ul class="list-inline mrgn-bttm-0">
                    <li>
                      <button
                        className="headerLngButton"
                        lang="fr"
                        onClick={() => languageChange()}
                      >
                        <span class="hidden-xs">
                          {currentLocale === "fr"
                            ? t("GovernmentOfCanadaMenu.en")
                            : t("GovernmentOfCanadaMenu.fr")}
                        </span>
                        <span className="visible-xs">
                          {currentLocale === "fr"
                              ? t("GovernmentOfCanadaMenu.enShort")
                              : t("GovernmentOfCanadaMenu.frShort")}
                        </span>
                        <abbr
                          title={
                            currentLocale === "fr"
                              ? t("GovernmentOfCanadaMenu.en")
                              : t("GovernmentOfCanadaMenu.fr")
                          }
                          class="visible-xs h3 mrgn-tp-sm mrgn-bttm-0 text-uppercase"
                        ></abbr>
                      </button>
                    </li>
                  </ul>
                </section>

                <div
                  class="brand col-xs-9 col-sm-5 col-md-4 text-left"
                  property="publisher"
                  typeof="GovernmentOrganization"
                >
                  <a href="https://www.canada.ca/en.html" property="url">
                    <img
                      src="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/sig-blk-en.svg"
                      alt="Government of Canada"
                      property="logo"
                    />
                    <span class="wb-inv">
                      / <span lang="fr">Gouvernement du Canada</span>
                    </span>
                  </a>
                  <meta property="name" content="Government of Canada" />
                  <meta
                    property="areaServed"
                    typeof="Country"
                    content="Canada"
                  />
                  <link
                    property="logo"
                    href="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/wmms-blk.svg"
                  />
                </div>

                <section
                  id="wb-srch"
                  class="col-lg-offset-4 col-md-offset-4 col-sm-offset-2 col-xs-12 col-sm-5 col-md-4"
                >
                  <h2>{t("GovernmentOfCanadaMenu.search")}</h2>
                  <form
                    method="get"
                    action={
                      "https://www.canada.ca/" + currentLocale + "/sr/srb.html"
                    }
                    name="cse-search-box"
                    role="search"
                  >
                    <div class="form-group wb-srch-qry">
                      <label htmlFor="wb-srch-q" class="wb-inv">
                        {t("GovernmentOfCanadaMenu.searchCanada")}
                      </label>
                      <input
                        id="wb-srch-q"
                        list="wb-srch-q-ac"
                        class="wb-srch-q form-control"
                        name="q"
                        type="search"
                        size="34"
                        maxLength="170"
                        placeholder={t("GovernmentOfCanadaMenu.searchCanada")}
                      />
                      <datalist id="wb-srch-q-ac"></datalist>
                    </div>
                    <div class="form-group submit">
                      <button
                        type="submit"
                        id="wb-srch-sub"
                        class="btn btn-primary btn-small"
                        name="wb-srch-sub"
                      >
                        <span class="glyphicon-search glyphicon"></span>
                        <span class="wb-inv">
                          {t("GovernmentOfCanadaMenu.search")}
                        </span>
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </div>
            <hr />
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <nav class="gcweb-menu" typeof="SiteNavigationElement">
                    <h2 class="wb-inv">t('GovernmentOfCanadaMenu.menu')</h2>
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded={menuOpen}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      <span class="wb-inv">
                        {t("GovernmentOfCanadaMenu.main")}{" "}
                      </span>
                      {t("GovernmentOfCanadaMenu.menu")}
                      <span class="expicon glyphicon glyphicon-chevron-down"></span>
                    </button>
                    <ul
                      role="menu"
                      aria-orientation="vertical"
                      className={"leftBlackMenu"}
                      data-ajax-replace="https://www.canada.ca/content/dam/canada/sitemenu/sitemenu-v2-en.html"
                    >
                      {menuItems}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </header>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.js"></script>
          <script src="https://wet-boew.github.io/themes-dist/GCWeb/wet-boew/js/wet-boew.min.js"></script>
          <script src="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/js/theme.min.js"></script>
        </body>
      </html>
    </div>
  );
};

export default Header;
