export const codeDescription = (code) => {
    const locale = localStorage.getItem("locale")
    if (code) {
        return locale === 'en' ? code.descriptionEnglish : code.descriptionFrench
    }
}

export const convertCode = (id, codeGroup) => {
    const lang = localStorage.getItem('locale')
    if (!codeGroup) {
        return id
    }
    for (const code of codeGroup) {
        if (parseInt(code.id)  === parseInt(id)) {
            return lang === 'en' ? code.descriptionEnglish : code.descriptionFrench
        }
    }
    return id;
}
