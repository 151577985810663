
/** Logger which outputs to the browser console */
const NO_OP = (message, ...optionalParams) => {};

const getLogLevel = (callback) => {
    callback(process.env.REACT_APP_LOG_LEVEL || 'log')
}

const check = () => {
    if (console.logs.length >= 2) {
        // console.log(console.logs)
        // send to backend and flush queue
        console.logs = []
    }
}


export class ConsoleLogger {

    context = {}

    createLog(type) {
        console['default'+type] = console[type].bind(console);
        return function(){
            // default & console.log()
            console['default'+type].apply(console, [this.context, Array.from(arguments)]);
            // new & array data
            console.logs.push({context: this.context, type, "datetime":new Date().toISOString(), "value": Array.from(arguments)});
            check()
        }
    }

    constructor(category) {

        if (category?.name)
            this.context['category'] = category?.name

        getLogLevel((level) => {

            if (!console.logs)
                console.logs = [];

            this.log = this.createLog('log')
            if (level === 'off') {
                this.warn = NO_OP;
                this.log = NO_OP;
                this.error = NO_OP
                return;
            }
            if (level === 'error') {
                this.warn = NO_OP;
                this.log = NO_OP;
                return;
            }
            this.warn = this.createLog('warn')
            if (level === 'warn') {
                this.log = NO_OP;
                return;
            }
            this.error = this.createLog('error')
        })
    }

    addContext(key,value) {
        this.context[key] = value
    }

    trace(message, ...optionalParams) {
        console.log(message, ...optionalParams);
    }

    log(message, ...optionalParams) {
        console.log(message, ...optionalParams);
    }

    warn(message, ...optionalParams) {
        console.warn(message, ...optionalParams);
    }

    error(message, ...optionalParams) {
        console.error(message, ...optionalParams);
    }
}
