import { createSlice } from "@reduxjs/toolkit";

export const SetUserCredentials = createSlice({
  name: "userCredentials",
  initialState: {
    userCredentials: {},
  },

  reducers: {
    checkRole: (state, action) => {
      //keep here
    },

    createUserHeader: (state, action) => {
      state.userCredentials = action.payload;
    },

    updateHasAppointment: (state, action) => {
      state.userCredentials.hasAppointment = action.payload;
    },
  },
});

export const { checkRole, createUserHeader, updateHasAppointment } =
  SetUserCredentials.actions;

export default SetUserCredentials.reducer;
