import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import "./ScheduleAppointment.css";
import "react-calendar/dist/Calendar.css";
import StartOverButton from "../Buttons/StartOverButton/StartOverButton";
import {
  getApplicationDetails,
  getAppointmentDetails,
  getDayAvailability,
  getLocationAvailability,
  postNewAppointment,
  putAppointment,
} from "../../util/ApiCalls";
import { StepTwo } from "./Steps/StepTwo";
import PostAppointmentUtil from "../../util/Function/PostAppointmentUtil";
import { getDate } from "../../Coverters/dateConverters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleHalfStroke,
  faCoffee,
  faCog,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../Loading/Loader";
import { findEnrollmentCenterInformation } from "../ViewAppointment/ViewAppointmentUtil";
import ConditionalRender from "../ConditonalRender/ConditionalRender";

const ScheduleAppointment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [client, setClient] = useState(null);
  const location = useLocation();
  const { portWorklocations } = useSelector((state) => state.portWorklocations);
  const [selectedAirport, setSelectedAirport] = useState("undefined");
  const [currentStep, setCurrentStep] = useState(1);

  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [appointmentPostError, setAppointmentPostError] = useState(false);
  const [selectedTime, setSelectedTime] = useState();
  const [worklocation, setWorklocation] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [requiredLocation, setRequiredLocation] = useState(true);
  const [requiredTime, setReuiredTime] = useState(true);
  const [date, setDate] = useState(new Date());
  let lang = localStorage.getItem("locale");

  /**
   * Find applicant
   * @returns {Promise<void>}
   */
  const findApplicantDetails = async () => {
    try {
      const applicant = await getApplicationDetails();
      // applicant not found
      // setClientNotFound(applicant.status === 404)
      if (applicant.status === 200) {
        const data = Array.isArray(applicant.data)
          ? applicant.data[0]
          : applicant.data;
        setClient(data);
      }
    } catch (err) {
      console.log(err);
      // setClientNotFound(true)
    }
  };

  useEffect(() => {
    if (portWorklocations) {
      findApplicantDetails();
    }
  }, [portWorklocations]);

  useEffect(() => {
    if (client?.clientId) {
      findAppointment();
    }
  }, [client]);

  /*
    todo - Eiad - Code clean up note
    When writing a generic method, please use generic param names.
    In this case, the method filters by param keys that have a certain param values.
    The param names should have been paramKey, paramValue. Calling them "canadianPort", which
    is the param value is confusing
   */
  function filterByValue(array, canadianPort, isUsa) {
    let returnArray = [];
    if (array) {
      for (let i = 0; i < array?.length; i++) {
        for (let j = 0; j < array[i].parameters.length; j++) {
          if (
            array[i]?.parameters[j]?.value === canadianPort &&
            array[i]?.parameters[j]?.key === isUsa
          ) {
            let results = array[i];

            returnArray.push(results);
          }
        }
      }
    }

    return returnArray;
  }

  /**
   * Find the applicant details to display on the page.
   * @returns {Promise<void>}
   */
  const findAppointment = async () => {
    try {
      const applicant = await getAppointmentDetails(client?.clientId);
      if (applicant.status === 200) {
        const data = Array.isArray(applicant.data)
          ? applicant.data[0]
          : applicant.data;
        setCurrentAppointment(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (portWorklocations.length !== 0) {
      let w = portWorklocations
        ?.map((ele) => {
          return {
            value: ele.id,
            label:
              lang === "en" ? ele.descriptionEnglish : ele.descriptionFrench,
            parameters: ele.parameters,
          };
        })
        .sort((o1, o2) => o1?.label?.localeCompare(o2?.label));

      let filterCanadianPorts = filterByValue(w, "false", "isUSA");

      setWorklocation(filterCanadianPorts);
      findAppointment();
    }
  }, [portWorklocations, lang]);

  useEffect(() => {
    if (currentAppointment)
      selectHandler(
        { target: { value: currentAppointment?.serviceLocationId } },
        worklocation
      );
  }, [currentAppointment]);

  const returnPrev = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    if (location.pathname === "/appointments") {
      navigate("/home");
    } else {
      navigate("/home");
    }
  };

  const selectHandler = (e, workLocations) => {
    if (e.target.value === "Select Airport") {
      setSelectedAirport(undefined);
    } else {
      for (const wl of workLocations) {
        if (parseInt(e.target.value) === parseInt(wl.value)) {
          setSelectedAirport(wl);
        }
      }
    }
  };

  const nextHandler = () => {
    if (selectedAirport === "undefined") {
      setRequiredLocation(false);
    } else {
      setCurrentStep(currentStep + 1);
      setRequiredLocation(true);
      window.scrollTo({top:10,behavior:'smooth'});
    }
  };

  const nextHandlerStepTwoToThree = (timeValue) => {
    if (!selectedTime) {
      setReuiredTime(false);
    } else {
      setReuiredTime(true);
      setSelectedTime(timeValue);
      setCurrentStep(currentStep + 1);
      window.scrollTo({top:10,behavior:'smooth'});
    }
  };

  const backHandler = () => {
    setDate(null);
    setAppointmentPostError(false);
    setCurrentStep(currentStep - 1);
    window.scrollTo({top:10,behavior:'smooth'});
  };

  const confirmHandler = async () => {
    let appointmentCall;
    setSubmitting(true);
    setAppointmentPostError(false);
    window.scrollTo({top:10,behavior:'smooth'});

    try {
      const details = PostAppointmentUtil(
        client,
        date,
        selectedTime,
        selectedAirport.value
      );
      if (currentAppointment) {
        details.id = currentAppointment.id;
        appointmentCall = await putAppointment(
          client.clientId,
          currentAppointment.id,
          details
        );
      } else {
        appointmentCall = await postNewAppointment(client.clientId, details);
      }
      if (appointmentCall?.status === 201 || appointmentCall?.status === 200) {
        navigate("/home", { state: { apponitmentCreated: true } });
      } else {
        setAppointmentPostError(true);
      }
    } catch (err) {
      setAppointmentPostError(true);
    }
    setSubmitting(false);
  };

  const cancelHandler = () => {
    navigate("/home");
    window.scrollTo({top:10,behavior:'smooth'});
  };

  const stepOne = (
    <div id={"wb-cont"} className="mainContainer">
      <div className="homeTitleContainer">
        <h1>{t("scheduleAppointmenPage.scheduleAppointmenTitle")}</h1>
      </div>
      <div className="schedule-txt-container">
        <p>{t("scheduleAppointmenPage.scheduleJointAppoinmentText")}</p>
      </div>
      <div key={selectedAirport?.value} className="pageContainer">
        <p>{t("scheduleAppointmenPage.scheduleAppoinmentText")}</p>
        <Loader
          content={
            <div className={"textContainer"}>
              <h2>{t("scheduleAppointmenPage.selectAirportRequired")}</h2>
              <br></br>
              {requiredLocation ? null : (
                <p className="required-text">
                  {t("scheduleAppointmenPage.errorRequired")}
                </p>
              )}
              <div>
                {worklocation.length > 0 ? (
                  <select
                    className="select-style"
                    onChange={(e) => selectHandler(e, worklocation)}
                    value={selectedAirport?.value}
                    name={"airport"}
                    aria-label="select a airport"
                    style={{
                      width: "30%",
                      padding: "10px",
                      borderRadius: "5px",
                      marginLeft: "0.5em",
                    }}
                  >
                    <option selected="selected" value="undefined">
                      {t("scheduleAppointmenPage.selectAirportRequired")}
                    </option>
                    {worklocation.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
              </div>
              <div div className="stepButtonContainer">
                <hr></hr>
                <button
                  onClick={nextHandler}
                  // disabled={selectedAirport !== "undefined" ? false : true}
                  className="buttonStyling"
                >
                  {t("button.next")}
                </button>
              </div>
            </div>
          }
          loading={worklocation?.length < 1}
          loadingLabel={t("loading.location")}
        />
      </div>
      <div className="bottomButtonContainer bottomNavSpacing">
        <button className="startOverBtn" onClick={returnPrev}>
          {t("button.back")}
        </button>
        <StartOverButton />
      </div>
    </div>
  );
  const updateDate = (day) => {

    setDate(day);

  };
  const stepTwo = (
    <StepTwo
      backHandler={backHandler}
      nextHandler={nextHandlerStepTwoToThree}
      wloc={selectedAirport}
      setSelectedTime={setSelectedTime}
      selectedTime={selectedTime}
      date={date}
      setDate={updateDate}
      requiredTime={requiredTime}
    />
  );

  const stepThree = (
    <div id={"wb-cont"} className="mainContainer">
      <div className="homeTitleContainer">
        <h1>{t("stepThree.Title")}</h1>
      </div>
      <div className="text-container">
        <p>{t("stepThree.confirmText")}</p>
      </div>
      <div className="top-element-error">
        <ConditionalRender
          comp={
            <span className="label label-danger">
              {t("stepThree.errorPostingAppointment")}
            </span>
          }
          condition={appointmentPostError}
        />
      </div>
      <div className="pageContainer">
        <Loader
          loading={submitting}
          content={
            <div className={"textContainer"}>
              <div>
                <b>{t("stepThree.selectAirport")} </b>
                {selectedAirport?.label}
              </div>
              <br></br>
              <div>
                <b>{t("stepThree.selectDate")} </b>
                {getDate(date,lang)}
              </div>
              <br></br>
              <div>
                <b>{t("stepThree.selectTime")} </b>
                {selectedTime}
              </div>
              <br></br>

              <div>
                <p>{t("stepThree.reminderText")}</p>
              </div>

              <div div className="step-three-submit">
                <button className="buttonStyling" onClick={confirmHandler}>
                  {t("stepThree.submit")}
                </button>
              </div>
            </div>
          }
          loadingLabel={t("stepThree.submittingAppointment")}
        />
      </div>
      <div className="bottomButtonContainer bottomNavSpacing">
        <button className="startOverBtn" onClick={backHandler}>
          {t("button.back")}
        </button>
        <button className="startOverBtn" onClick={cancelHandler}>
          {t("button.cancel")}
        </button>
        {/*<StartOverButton/>*/}
      </div>
    </div>
  );

  return (
    <>
      {currentStep === 1 ? stepOne : null}
      {currentStep === 2 ? stepTwo : null}
      {currentStep === 3 ? stepThree : null}
    </>
  );
};

export default ScheduleAppointment;
