import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import edec from "../../assets/edeclaration.svg";
import { useTranslation } from "react-i18next";
import {
  getApplicationDetails,
  getAppointmentDetails,
} from "../../util/ApiCalls";
import { trimDate } from "../../Coverters/dateConverters";
import { Loader } from "../../Components/Loading/Loader";
import { ErrorHandler } from "../../Components/ErrorHandler/ErrorHandler";
import { convertCode } from "../../util/Function/CodeUtil";
import { ConsoleLogger } from "../../Logger/Logger";
import { findClient } from "../../Service/ClientService";
import { hasAppointment } from "../../Service/AppointmentService";

export const buildName = (client) => {
  return client?.clientName;
};

const ClientInformation = ({ navBtn, midInfo, viewBtn, scheduleBtn }) => {
  const { programType, applicationType } = useSelector(
    (state) => state.portWorklocations
  );
  const { t } = useTranslation();

  const [client, setClient] = useState(null);
  const [clientNotFound, setClientNotFound] = useState(null);
  const [client500, setClient500] = useState(false);
  const [hasAAppointment, setHasAAppointment] = useState(null);

  useEffect(() => {
    findClient(setClient, setClientNotFound, setClient500).catch((err) =>
      setClient500(true)
    );
  }, []);

  useEffect(() => {
    if (client) {
      hasAppointment(client.clientId, setHasAAppointment).catch((err) =>
        setClient500(true)
      );
    }
  }, [client]);

  let btnContent = undefined;

  if (scheduleBtn && viewBtn) {
    if (hasAAppointment) {
      btnContent = viewBtn;
    } else {
      btnContent = scheduleBtn;
    }
  }

  return (
    <>
      <div className="pageContainer flex">
        <Loader
          loading={!client && !clientNotFound && hasAAppointment === null}
          hasError={client500}
          errorContent={<div>error</div>}
          content={
            <>
              <div className="textContainer">
                <p>
                  <b>{t("homePage.applicantName")} </b>
                  {buildName(client)}
                </p>

                <p>
                  <b>{t("homePage.usPassId")}</b> {client?.passId}
                </p>

                <p>
                  <b>{t("homePage.program")}</b>{" "}
                  {convertCode(client?.applicationProgram, applicationType)}
                </p>

                <p>
                  <b>{t("homePage.applicationType")}</b>{" "}
                  {convertCode(client?.applicationType, programType)}
                </p>
                <br />
                {navBtn}
                {btnContent}
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
export default ClientInformation;
