import React from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ViewAppointmentButton = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  /**
   * Change to the view page
   */
  const viewAppointmentHandler = () => {
    navigate("/view-appointment");
  };

  /**
   * Return a button
   */
  return (
    <button className="scheduleButton" onClick={viewAppointmentHandler}>
      {t('viewAppointment.manageInterviewAppointment')}
    </button>
  );
};

export default ViewAppointmentButton;
