import {trimTDate} from "../../Coverters/dateConverters";

const PostAppointmentUtil = (object, selectedDate, selectedTime, locationId) => ({
    clientId: object.clientId.toString(),
    date: trimTDate(selectedDate.toISOString()),
    time: selectedTime,
    locationId: parseInt(locationId)
})

export default PostAppointmentUtil;
