export const Jobs = ({ showing, en }) => {
  if (!showing) return;

  if (en === "fr") {
    return (
      <ul id="gc-mnu-jobs" role="menu" aria-orientation="vertical">
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/emplois.html"
          >
            Emplois
            <span className="visible-xs-inline visible-sm-inline">
              {" "}
              : accueil
            </span>
          </a>
        </li>
        <li role="separator"></li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/emplois/opportunites.html"
          >
            Trouver un emploi
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/emplois/formation.html"
          >
            Formation
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/gestion-entreprise"
          >
            Embauche et gestion de personnel
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/demarrage-entreprise"
          >
            Démarrage d'entreprise
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/emplois/milieu-travail.html"
          >
            Normes en milieu de travail
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/finance/pensions.html"
          >
            Pensions et retraite
          </a>
        </li>
        <li role="presentation">
          <a
            role="menuitem"
            tabIndex="-1"
            href="https://www.canada.ca/fr/services/prestations/ae.html"
          >
            Prestations d'assurance-emploi et congés
          </a>
        </li>
        <li role="separator" aria-orientation="horizontal"></li>
        <li role="presentation">
          <a
            data-keep-expanded="md-min"
            href="#"
            role="menuitem"
            tabIndex="-1"
            aria-haspopup="true"
            aria-controls="gc-mnu-jobs-sub"
            aria-expanded="true"
          >
            En demande
          </a>
          <ul id="gc-mnu-jobs-sub" role="menu" aria-orientation="vertical">
            <li role="presentation">
              <a
                role="menuitem"
                tabIndex="-1"
                href="https://www.canada.ca/fr/emploi-developpement-social/programmes/assurance-emploi/ae-liste/assurance-emploi-re/acceder-re.html"
              >
                Voir vos Relevés d’emploi
              </a>
            </li>
            <li role="presentation">
              <a
                role="menuitem"
                tabIndex="-1"
                href="https://www.canada.ca/fr/emploi-developpement-social/services/numero-assurance-sociale.html"
              >
                Demander un numéro d’assurance-sociale
              </a>
            </li>
            <li role="presentation">
              <a
                role="menuitem"
                tabIndex="-1"
                href="https://www.canada.ca/fr/emploi-developpement-social/services/travailleurs-etrangers.html"
              >
                Embaucher un travailleur étranger temporaire
              </a>
            </li>
            <li role="presentation">
              <a
                role="menuitem"
                tabIndex="-1"
                href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/immigrer-canada/entree-express.html"
              >
                Immigrer en tant que travailleur qualifié
              </a>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
  return (
    <ul id="gc-mnu-jobs" role="menu" aria-orientation="vertical">
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/jobs.html"
        >
          Jobs
          <span className="visible-xs-inline visible-sm-inline">: home</span>
        </a>
      </li>
      <li role="separator"></li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/jobs/opportunities.html"
        >
          Find a job
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/jobs/training.html"
        >
          Training
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/business-management"
        >
          Hiring and managing employees
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/start-business"
        >
          Starting a business
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/jobs/workplace.html"
        >
          Workplace standards
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/finance/pensions.html"
        >
          Pensions and retirement
        </a>
      </li>
      <li role="presentation">
        <a
          role="menuitem"
          tabIndex="-1"
          href="https://www.canada.ca/en/services/benefits/ei.html"
        >
          Employment Insurance benefits and leave
        </a>
      </li>
      <li role="separator" aria-orientation="horizontal"></li>
      <li role="presentation">
        <a
          data-keep-expanded="md-min"
          href="#"
          role="menuitem"
          tabIndex="-1"
          aria-haspopup="true"
          aria-controls="gc-mnu-jobs-sub"
          aria-expanded="true"
        >
          Most requested
        </a>
        <ul id="gc-mnu-jobs-sub" role="menu" aria-orientation="vertical">
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              href="https://www.canada.ca/en/employment-social-development/programs/ei/ei-list/ei-roe/access-roe.html"
            >
              View your Records of Employment
            </a>
          </li>
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              href="https://www.canada.ca/en/employment-social-development/services/sin.html"
            >
              Apply for a Social Insurance Number (SIN)
            </a>
          </li>
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              href="https://www.canada.ca/en/employment-social-development/services/foreign-workers.html"
            >
              Hire a temporary foreign worker
            </a>
          </li>
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry.html"
            >
              Immigrate as a skilled worker
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};
