export const PolicingJusticeAndEmergency = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return  (
            <ul id="gc-mnu-policing" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police.html">Services de police<span
                    className="hidden-xs hidden-sm">, justice et urgences</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/servicespolice.html">Services
                    de police</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/justice.html">Justice</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/urgences.html">Urgences</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/correctionnels.html">Services
                    correctionnels</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/liberationconditionnelle.html">Libération
                    conditionnelle, suspension du casier, radiation et clémence</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/victimes.html">Victimes
                    d'actes criminels</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-policing-sub"
                                           aria-expanded="true">En demande</a>
                    <ul id="gc-mnu-policing-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.rcmp-grc.gc.ca/cfp-pcaf/online_en-ligne/index-fra.htm">Demander
                            ou renouveler un permis d'arme à feu</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.rcmp-grc.gc.ca/fr/verification-casier-judiciaire">Obtenir
                            une attestation de vérification de casier judiciaire</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/commission-liberations-conditionnelles/services/suspension-du-casier/guide-et-formulaires-de-demande.html">Demander
                            la suspension d’un casier judiciaire</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.preparez-vous.gc.ca/cnt/hzd/drng-fr.aspx">Que faire
                            durant une urgence</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/police/servicespolice/securite-communautaire-police/conduite-facultes-affaiblies.html">Connaissez
                            la loi sur la conduite avec facultés affaiblies</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/police/servicespolice/aider-resoudre-un-crime.html">Aidez
                            à résoudre un crime</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-policing" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing.html">Policing<span
                className="hidden-xs hidden-sm">, justice and emergencies</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/police/index.html">Policing</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/justice.html">Justice</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/emergencies.html">Emergencies</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/corrections.html">Corrections</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/parole.html">Parole, record
                suspension, expungement and clemency</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/policing/victims.html">Victims of
                crime</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-policing-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-policing-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.rcmp-grc.gc.ca/cfp-pcaf/online_en-ligne/index-eng.htm">Apply/Renew
                        a firearms licence</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.rcmp-grc.gc.ca/en/criminal-record-checks">Get a criminal
                        records check</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/parole-board/services/record-suspensions/official-pbc-application-guide-and-forms.html">Apply
                        for a criminal record suspension</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.getprepared.gc.ca/cnt/hzd/drng-en.aspx">What to do
                        during an emergency</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/policing/police/community-safety-policing/impaired-driving.html">Know
                        the law on impaired driving</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/policing/police/help-solve-crime.html">Help
                        solve a crime</a></li>
                </ul>
            </li>
        </ul>
    )
}
