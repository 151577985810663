import React from "react";
import "./ReturnTTPButton.css";
import { useTranslation } from "react-i18next";
// import Cookies from "universal-cookie";

const ReturnTTPButton = ({ maint }) => {
  const { t } = useTranslation();

  const returnHandler = () => {
    // const cookies = new Cookies();
    // let redirect = cookies.get('user-redirect')
    window.scrollTo({ top: 10, behavior: "smooth" });
    let redirect = localStorage.getItem("user-redirect");
    if (redirect) {
      if (!redirect.startsWith("https://") && !redirect.startsWith("http://")) {
        redirect = "https://" + redirect;
      }
      window.location.href = redirect;
    }
    return null;
  };

  return (
    <div className="ttpContainer">
      {maint ? (
        <button
          className="startOverBtn returnBtnColour"
          onClick={returnHandler}
        >
          Return to U.S. Dashboard / Retour au tableau de bord des É.-U.
        </button>
      ) : (
        <button
          className="startOverBtn returnBtnColour"
          onClick={returnHandler}
        >
          {t("returnToTTP")}
        </button>
      )}
    </div>
  );
};

export default ReturnTTPButton;
