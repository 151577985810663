import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentLanguage } from "../../Redux/SetUserLanguage";
import i18n from "../i18n";
import "./LangSelect.css";

const LangSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Load home in english
   */
  const nextEngHandler = () => {
    navigate("/home");
    localStorage.setItem("locale", "en");
    i18n.changeLanguage("en");
    dispatch(currentLanguage("en"));
  };

  /**
   * Load home page in french
   */
  const nextFrHandler = () => {
    navigate("/home");
    localStorage.setItem("locale", "fr");
    i18n.changeLanguage("fr");
    dispatch(currentLanguage("fr"));
  };

  return (
    <div className={"splash"}>
      <div className={"splash"}>
        <div id="bg" className="wb-init wb-randomize-inited splash">
          <img className="" src="/sp-bg-2.jpg" alt="" />
        </div>
      </div>
      <main
        property="mainContentOfPage"
        resource="#wb-main"
        typeof="WebPageElement"
      >
        <div className="sp-hb">
          <div className="sp-bx col-xs-12">
            <h1 property="name" className="wb-inv">
              Canada.ca
            </h1>
            <div className="row">
              <div
                className="col-xs-11 col-md-8"
                property="publisher"
                resource="#wb-publisher"
                typeof="GovernmentOrganization"
              >
                <img
                  src="https://wet-boew.github.io/themes-dist/GCWeb/GCWeb/assets/sig-blk-en.svg"
                  alt="Government of Canada"
                  className="logo"
                  property="logo"
                  width="480"
                />
                <span className="wb-inv">
                  {" "}
                  / <span lang="fr">Gouvernement du Canada</span>
                </span>
                <meta property="name" content="Government of Canada" />
                <meta
                  property="name"
                  lang="fr"
                  content="Gouvernement du Canada"
                />
                <meta property="areaServed" typeof="Country" content="Canada" />
              </div>
            </div>
            <div className="row">
              <section className="col-xs-6 text-center title-en">
                <strong>CBSA Interview Scheduler</strong>{" "}
              </section>
              <section className="col-xs-6 text-center title-fr">
                <strong>Calendrier d'entrevues de l’ASFC</strong>
              </section>
            </div>
            <div className="row">
              <section className="col-xs-6 text-right">
                <h2 className="wb-inv">Government of Canada</h2>
                <button
                  onClick={nextEngHandler}
                  className="btn btn-primary btn-home"
                >
                  English
                </button>
              </section>
              <section className="col-xs-6" lang="fr">
                <h2 className="wb-inv">Gouvernement du Canada</h2>
                <button
                  onClick={nextFrHandler}
                  className="btn btn-primary btn-home"
                >
                  Français
                </button>
              </section>
            </div>
          </div>
          <div className="sp-bx-bt col-xs-12">
            <div className="row">
              <div className="col-xs-7 col-md-8 text-left">
                <a
                  href="https://www.canada.ca/en/transparency/terms.html"
                  className="sp-lk text-left"
                >
                  Terms &amp; conditions
                </a>{" "}
                <span className="glyphicon glyphicon-asterisk"></span>{" "}
                <a
                  href="https://www.canada.ca/fr/transparence/avis.html"
                  className="sp-lk"
                  lang="fr"
                >
                  Avis
                </a>
              </div>
              <div className="col-xs-5 col-md-4 text-right mrgn-bttm-md">
                <img
                  src="/wmms-blk.svg"
                  width="127"
                  alt="Symbol of the Government of Canada"
                />
                <span className="wb-inv">
                  {" "}
                  / <span lang="fr">Symbole du gouvernement du Canada</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default LangSelect;
