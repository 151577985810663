import axios from "axios";
// import Cookies from "universal-cookie";
import { ConsoleLogger } from "../Logger/Logger";

const logger = new ConsoleLogger();

export const GE_RES_CODES = {
  OK: 200,
  UNAUTHORIZED: 401,
};

let config;

/**
 * Retrieve the config endpoint
 * @returns {Promise<void>}
 */
export const getConfig = async () => {
  const baseUrl = window.location.origin;
  if (!config) {
    const configFile = await axios.get(baseUrl + "/config/config.json");
    config = configFile.data.apiUrl;

  }
};

/**
 * Collect the token and the url
 * @returns {Promise<string>}
 */
export const getToken = async () => {
  // get the url from the config file
  if (window.location.origin.startsWith("http://localhost")) {
    config = process.env.REACT_APP_URL;
  } else {
    await getConfig();
  }

  // if token is undefined the user may have deleted it from the url
  let token = localStorage.getItem("myToken");

  if (!token) {
    // First call, we may only have the access id for the token
    const accessId = localStorage.getItem("myAccessId");
    if (accessId) {
      try {
        const results = await axios.get(`${config}/token/accessId/${accessId}`);
        if (results.status === 200) {
          token = results.data.Authorization;
        } else {
          logger.error("get-token status: " + results.status);
          token = accessId;
        }
      } catch (err) {
        logger.error("Could not retrieve token from access id");
        token = accessId; // for testing we can use the token directly in the url
      }
    }

    if (token) {
      localStorage.setItem("myToken", token);
    }
  }

  // return the user tokem
  return token;
};

const needsClientId = (path) => {
  return false;
};

/**
 * Handle all axios requests
 * @param endpoint
 * @param type
 * @param body
 * @returns {Promise<*|AxiosResponse<any>>}
 */
const handleRequest = async (endpoint, type = "get", body = undefined) => {
  try {
    const Authorization = await getToken();

    const headers = {
      Authorization,
    };

    if (needsClientId(endpoint)) {
      headers.clientId = 123;
    }

    if (type === "post" || type === "put") {
      return await axios[type](config + endpoint, body, {
        headers,
      });
    }
    return await axios[type](config + endpoint, {
      headers,
    });
  } catch (err) {
    // if any endpoints return a 401 then we return to the unauthed page
    if (err?.response?.status === GE_RES_CODES.UNAUTHORIZED) {
      if (window.location.pathname !== "/ttp/unauthorized") {
        window.location.pathname = "/ttp/unauthorized";
      }
    }
    logger.error(err);
    return err.response;
  }
};

/**
 * API endpoints
 * @returns {Promise<*|AxiosResponse<*>>}
 */
export const getApplicationInformation = async () =>
  handleRequest("appointment");

export const getApplicationDetails = async () => handleRequest("applicant");

export const getAppointmentDetails = async (clientId) =>
  handleRequest(`client/${clientId}/appointment`);

export const cancelAppointment = async (clientId, appointmentId) =>
  handleRequest(`client/${clientId}/appointment/${appointmentId}`, "delete");

export const getLocationAvailability = async (locationId, start, end) =>
  handleRequest(
    `location/${locationId}/availability?start=${start}&end=${end}`
  );

// returns Availability periods array or undefined if status !200
export const getDayAvailability = async (locationId, date) =>
  handleRequest(`location/${locationId}/availability/${date}`);

export const postNewAppointment = async (clientId, body) =>
  handleRequest(`client/${clientId}/appointment`, "post", body);

export const putAppointment = async (clientId, id, body) =>
  handleRequest(`client/${clientId}/appointment/${id}`, "put", body);

export const getWorklocationsFromApi = async () => handleRequest(`codelist`);

export const authenticateToken = async () => handleRequest(`authorize`);

export const getApplicationStatus = async () =>
  handleRequest(`system/status`, "get");
