import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./StartOverModal.css";

const StartOverModal = ({ setShowModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const returnHandler = () => {
    navigate("/home");
  };

  return (
    <div className="modalContainer">
      <div className="modal-main">
        <h2 className="cancelTitle"> {t("cancelModal.title")}</h2>
        <div className="cancelTextContainer">
          <br></br>
          <p>{t("cancelModal.text")}</p>
          <br></br>
        </div>

        <footer>
          <div className="cancelButtonContainer">
            
            <button
              className="startOverBtn"
              type="button"
              onClick={() => setShowModal(false)}
            >
              {t("button.cancel")}
            </button>
            <button
              className="cancelButtonStyle"
              type="button"
              onClick={() => returnHandler()}
            >
              {t("button.confirm")}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default StartOverModal;
