export const NationalSecurityAndDefense = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-defence" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense.html"><span
                    className="hidden-xs hidden-sm">Sécurité nationale et défense</span><span
                    className="visible-xs-inline visible-sm-inline">Défense : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/securitenationale.html">Sécurité
                    nationale</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/fac.html">Forces armées
                    canadiennes</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/achat-mise-a-niveau-equipement-defense.html">Achat
                    et mise à niveau d’équipement de la Défense</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.tc.gc.ca/fr/services/surete-transports.html">Sûreté des
                    transports</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/securiserfrontiere.html">Sécuriser
                    la frontière</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/cybersecurite.html">Cybersécurité</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/defense/emplois.html">Emplois en
                    sécurité nationale et en défense</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/gouvernement/fonctionpublique/avantagesmilitaires.html">Services
                    et avantages sociaux du personnel militaire</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-defence-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-defence-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://forces.ca/fr/carrieres/">Emplois
                            dans les Forces armées canadiennes</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/ministere-defense-nationale/services/histoire-militaire/histoire-patrimoine/insignes-drapeaux/grades/insignes-grade-fonction.html">Grades
                            militaires</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/defense/fac/equipement.html">Équipement
                            de la Défense</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/ministere-defense-nationale/services/cadets-rangers-juniors-canadiens/cadets/rejoignez-nous.html">Joignez-vous
                            aux cadets</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://dgpaapp.forces.gc.ca/fr/politique-defense-canada/index.asp">Politique
                            de défense du Canada</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-defence" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence.html"><span
                className="hidden-xs hidden-sm">National security and defence</span><span
                className="visible-xs-inline visible-sm-inline">Defence: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/nationalsecurity.html">National
                security</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/caf.html">Canadian Armed
                Forces</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/defence-equipment-purchases-upgrades.html">Defence
                equipment purchases and upgrades</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.tc.gc.ca/en/services/transportation-security.html">Transportation
                security</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/securingborder.html">Securing the
                border</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/cybersecurity.html">Cyber
                security</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/defence/jobs.html">Jobs in national
                security and defence</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/government/publicservice/benefitsmilitary.html">Services
                and benefits for the military</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-defence-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-defence-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://forces.ca/en/careers/">Jobs
                        in the Canadian Armed Forces</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/department-national-defence/services/military-history/history-heritage/insignia-flags/ranks/rank-appointment-insignia.html">Military
                        ranks</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/defence/caf/equipment.html">Defence
                        equipment</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.publicsafety.gc.ca/cnt/ntnl-scrt/cntr-trrrsm/lstd-ntts/crrnt-lstd-ntts-en.aspx">Current
                        list of terrorist entities</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/department-national-defence/services/cadets-junior-canadian-rangers/cadets/join-us.html">Join
                        the Cadet Program</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://dgpaapp.forces.gc.ca/en/canada-defence-policy/index.asp">Canada's
                        Defence policy</a></li>
                </ul>
            </li>
        </ul>
    )
}
