import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CancelAppointmentButton from "../Buttons/CancelAppointmentButton/CancelAppointmentButton";
import RescheduleAppointmentButton from "../Buttons/RescheduleAppointmentButton/RescheduleAppointmentButton";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

import "./ViewAppointment.css";
import {
  getApplicationDetails,
  getAppointmentDetails,
} from "../../util/ApiCalls";
import {
  findAppointment,
  findEnrollmentCenterInformation,
  getEmails,
  getPhoneNumbers,
} from "./ViewAppointmentUtil";
import { codeDescription, convertCode } from "../../util/Function/CodeUtil";
import { findParam, getDirections } from "../../util/Function/CodeParametersUtil";
import { trimDate, trimTime } from "../../Coverters/dateConverters";
import { Loader } from "../Loading/Loader";
import BackButton from "../Buttons/BackButton/Back";
import { buildName } from "../ClientInformation/ClientInformation";
import { useLocation } from "react-router-dom";
import ReturnTTPButton from "../Buttons/ReturnTTPButton/ReturnTTPButton";

const ViewAppointment = ({ client, client500, clientNotFound }) => {
  const { programType, applicationType, portWorklocations } = useSelector(
    (state) => {

      return state.portWorklocations;
    }
  );
  const { t } = useTranslation();
  const [enrollmentCenter, setEnrollmentCenter] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [appointmentNotFound, setAppointmentNotFound] = useState(null);

  const pdfRef = useRef(null);
  useEffect(() => {
    if (client?.clientId) {
      findAppointment(client.clientId, setAppointment, setAppointmentNotFound);
    }
  }, [client]);

  const { state } = useLocation();

  let title = codeDescription(enrollmentCenter);

  let locationAddress = findParam(enrollmentCenter, "locationAddress");
  let locationDirections = getDirections(enrollmentCenter, t("viewAppointment.directionsLang"));
  findParam(enrollmentCenter, "locationAddress");

  useEffect(() => {

    if (appointment) {
      setEnrollmentCenter(
        findEnrollmentCenterInformation(appointment, portWorklocations)
      );
    }
  }, [appointment, locationDirections, portWorklocations]);




  return (
    <div className="mainContainer">
      <div className="viewAppointmentTitleContainer">
        <h1> {t("viewAppointment.globalEnrollement")}</h1>
      </div>
      <div>
        {state?.apponitmentCreated ? (
          <div className="confirm-container">
            <div class="alert alert-success"></div>
            <p className="confirm-padding">{t("viewAppointment.confirmed")}</p>
          </div>
        ) : null}

        <p className="reschedule-note">{t("viewAppointment.note")}</p>
        <div className="confirm-container">
          <div class="alert alert-info"></div>
          <p className="please-note-padding">
            <Trans i18nKey="viewAppointment.text" />
          </p>
        </div>
      </div>

      <div className="viewAppointmentContentContainer">
        <div className="pageContainer">
          {clientNotFound ? (
            <span>The client was not found</span>
          ) : (
            <Loader
              loading={!enrollmentCenter && !appointment && !title}
              content={
                <>
                  <div className={"flex"} id={"printContent"} ref={pdfRef}>
                    <div className="appointmentLocationContainer">
                      <h1 className="viewApplicantTitle">
                        {t("apntInfo.homeTitle")}
                      </h1>
                      <p>
                        <b>{t("apntInfo.interviewDate")} </b>{" "}
                        {appointment?.date}
                      </p>
                      <p>
                        <b>{t("apntInfo.interviewTime")} </b>{" "}
                        {trimTime(appointment?.time)}
                      </p>
                      <p>
                        <b>{t("apntInfo.interviewLocation")} </b>{" "}
                        {codeDescription(enrollmentCenter)}
                      </p>
                      <p>
                        <b>{t("apntInfo.address")} </b>{" "}
                        {locationAddress}
                      </p>
                      <p>
                        <b>{t("apntInfo.enrolmentCenterDirection")} </b>{" "}
                        {locationDirections}
                      </p>
                    </div>
                    <div className="appointmentApplicantContainer">
                      <h1 className="viewApplicantTitle">
                        {t("apntInfo.homeApplicantTitle")}
                      </h1>
                      <div className="textContainer">
                        <p>
                          <b>{t("homePage.applicantName")}</b>
                          <span tabIndex={"0"}>{buildName(client)}</span>
                        </p>

                        <p>
                          <b>{t("homePage.usPassId")}</b>
                          <span tabIndex={"0"}>{client?.passId}</span>
                        </p>

                        <p>
                          <b>{t("homePage.program")}</b>
                          <span tabIndex={"0"}>
                            {convertCode(
                              client?.applicationProgram,
                              applicationType
                            )}
                          </span>
                        </p>

                        <p>
                          <b>{t("homePage.applicationType")}</b>
                          <span tabIndex={"0"}>
                            {convertCode(client?.applicationType, programType)}
                          </span>
                        </p>

                        <br />
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="text-left">
                      <>
                        <ReactToPrint
                          content={() => pdfRef.current}
                          trigger={() => (
                            <button className="buttonStyling" type={"submit"}>
                              {t("appointmentConfirmed.print")}
                            </button>
                          )}
                        />
                      </>
                      <RescheduleAppointmentButton />
                      <CancelAppointmentButton />
                    </div>

                    <div>
                      <ReturnTTPButton />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAppointment;
