import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import translation jsons
import EN from "../locales/en/translation.json";
import FR from "../locales/fr/translation.json";

const getUserStoredLocaleSetting = () => {
  let userLocaleSettings = localStorage.getItem("locale");

  // If there is no user locale, default is english "en"
  if (!userLocaleSettings) {
    userLocaleSettings = "en";
    localStorage.setItem("locale", "en");
  }

  // Return our locale value to i18next
  return userLocaleSettings;
};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resources for i18n to use in useTranslation to display correct text.
    resources: {
      en: { translation: EN },
      fr: { translation: FR },
    },

    fallbackLng: getUserStoredLocaleSetting(),
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
