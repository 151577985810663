import React from "react";
import { useTranslation } from "react-i18next";
import "./AddtionalInformation.css";

const AddtionalInformation = () => {
  const { t } = useTranslation();

  return (
    <ul class="list-unstyled">
      <li>
        <details>
          <summary>{t("addtionalInformation.title")}</summary>
          <p>{t("addtionalInformation.textOne")}</p>
          <ul>
            <li className="addtional-li-style">
              {t("addtionalInformation.textTwo")}
            </li>
            <li className="addtional-li-style">
              {t("addtionalInformation.textThree")}
            </li>
            <li className="addtional-li-style">
              {t("addtionalInformation.textFour")}
            </li>
            <li className="addtional-li-style">
              {t("addtionalInformation.textFive")}
            </li>
          </ul>
        </details>
      </li>
    </ul>
  );
};

export default AddtionalInformation;
