import { trimTime } from "../../Coverters/dateConverters";
import { codeDescription } from "../../util/Function/CodeUtil";
import { findParam } from "../../util/Function/CodeParametersUtil";
import React from "react";
import { useTranslation } from "react-i18next";

export const AppointmentDetails = ({ appointment, enrollmentCenter }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row pad-bottom-1">
        <div className="col-xs-3 col-md-3 text-left bold">
          <span tabIndex={0}>{t("apntInfo.interviewDate")}</span>
        </div>
        <div className="col-xs-6 col-md-6 text-left">
          <span tabIndex={"0"}>{appointment?.date}</span>
        </div>
      </div>
      <div className="row pad-bottom-1">
        <div className="col-xs-3 col-md-3 text-left bold">
          <span tabIndex={0}>{t("apntInfo.interviewTime")}</span>
        </div>
        <div className="col-xs-6 col-md-6 text-left">
          <span tabIndex={"0"}>{trimTime(appointment?.time)}</span>
        </div>
      </div>
      <div className="row pad-bottom-1">
        <div className="col-xs-3 col-md-3 text-left bold">
          <span tabIndex={0}>{t("apntInfo.enrollmentCenter")}</span>
        </div>
        <div className="col-xs-6 col-md-6 text-left">
          <span tabIndex={"0"}>{codeDescription(enrollmentCenter)}</span>
        </div>
      </div>
      <div className="row pad-bottom-1">
        <div className="col-xs-3 col-md-3 text-left bold">
          <span tabIndex={0}>{t("apntInfo.address")}</span>
        </div>
        <div className="col-xs-6 col-md-6 text-left">
          <span tabIndex={"0"}>
            {" "}
            {findParam(enrollmentCenter, "locationAddress")}
          </span>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
