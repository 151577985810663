import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ScheduleAppointmentButton from "../../Components/Buttons/ScheduleAppointmentButton/ScheduleAppointmentButton";
import ViewAppointmentButton from "../../Components/Buttons/ViewAppointmentButton/ViewAppointmentButton";
import { useTranslation, Trans } from "react-i18next";

import "./Home.css";
import { getApplicationDetails } from "../../util/ApiCalls";
import ReturnTTPButton from "../../Components/Buttons/ReturnTTPButton/ReturnTTPButton";
import ClientInformation from "../../Components/ClientInformation/ClientInformation";
import { ConsoleLogger } from "../../Logger/Logger";
import ViewAppointment from "../../Components/ViewAppointment/ViewAppointment";
import { findClient } from "../../Service/ClientService";
import { hasAppointment } from "../../Service/AppointmentService";
import { Loader } from "../../Components/Loading/Loader";
import AddtionalInformation from "../../Components/AddtionalInformartion/AddtionalInformation";

const Home = () => {
  const logger = new ConsoleLogger();
  logger.log("Landed on the home page!");

  const [client, setClient] = useState(null);
  const [clientNotFound, setClientNotFound] = useState(null);
  const [client500, setClient500] = useState(false);
  const [hasAAppointment, setHasAAppointment] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    findClient(setClient, setClientNotFound, setClient500).catch((err) =>
      setClient500(true)
    );
  }, []);

  useEffect(() => {
    if (client) {
      hasAppointment(client.clientId, setHasAAppointment).catch((err) =>
        setClient500(true)
      );
    }
  }, [client]);

  if (hasAAppointment === true) {
    return (
      <ViewAppointment
        client={client}
        client500={client500}
        clientNotFound={clientNotFound}
      />
    );
  }

  return (
    <>
      <div id={"wb-cont"} className="mainContainer">
        <div className="homeTitleContainer">
          <h1>{t("homePage.homePageTitle")}</h1>
        </div>
        <div className="home-text-container">
          <Trans i18nKey="homePage.homePageDescriptionText" />
          <Trans i18nKey="homePage.homePagePleaseNote" />
          <div className="session-exp-container">
            <div class="alert alert-info"></div>
            <p className="session-exp-text">
              <Trans i18nKey="homePage.sessionExpire" />
            </p>
          </div>

          <AddtionalInformation />
        </div>
        <Loader
          loading={hasAAppointment === null}
          content={
            <>
              <div>
                <ClientInformation
                  viewBtn={<ViewAppointmentButton />}
                  scheduleBtn={<ScheduleAppointmentButton />}
                />
                <ReturnTTPButton />
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
export default Home;
