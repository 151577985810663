import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./Maintenance.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ReturnTTPButton from "../../Components/Buttons/ReturnTTPButton/ReturnTTPButton";

const Maintenance = ({ applicationStatusText }) => {
  console.log(applicationStatusText);
  return (
    <>
      <Header
        key={window.location.pathname}
        shouldShow={window.location.pathname !== "/"}
      />

      {Object.keys(applicationStatusText).length !== 0 ? (
        <div style={{ padding: "20px" }}>
          <div>
            <h1>{applicationStatusText?.title?.en}</h1>
            <p>{applicationStatusText?.text?.en}</p>
          </div>

          <div>
            <h1>{applicationStatusText?.title?.fr}</h1>
            <p>{applicationStatusText?.text?.fr}</p>
          </div>

          <ReturnTTPButton maint={true} />
        </div>
      ) : null}

      <Footer shouldShow={window.location.pathname !== "/"} />
    </>
  );
};

export default Maintenance;
