export const Health = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-health" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante.html">Santé<span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/aliments-et-nutrition.html">Aliments
                    et nutrition</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/sante-publique/services/maladies.html">Maladies
                    et affections</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/sante-publique/sujets/immunisation-et-vaccins.html">Vaccins
                    et immunisation</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/medicaments-et-produits-sante.html">Médicaments
                    et produits de santé</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/securite-produits.html">Sécurité
                    des produits</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/securite-et-risque-pour-sante.html">Sécurité
                    et risque pour la santé</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/vie-saine.html">Vie saine</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/sante-autochtones.html">Santé
                    des Autochtones</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/systeme-et-services-sante.html">Système
                    et services de santé</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/sante/science-recherche-et-donnees.html">Science,
                    recherche et données</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-health-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-health-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/sante-canada/services/drogues-medicaments/cannabis/titulaires-licences-demandeurs-industrie/cultivateurs-transformateurs-vendeurs-autorises.html">Cultivateurs,
                            transformateurs et vendeurs de cannabis qui détiennent une licence</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canadiensensante.gc.ca/recall-alert-rappel-avis/index-fra.php">Rappels
                            d'aliments et de produits et alertes de sécurité</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/sante-canada/services/guides-alimentaires-canada.html">Guide
                            alimentaire du Canada</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-health" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health.html">Health<span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/food-nutrition.html">Food and
                nutrition</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/public-health/services/diseases.html">Diseases and
                conditions</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/public-health/topics/immunization-vaccines.html">Vaccines
                and immunization</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/drug-health-products.html">Drug
                and health products</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/product-safety.html">Product
                safety</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/health-risks-safety.html">Health
                risks and safety</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/healthy-living.html">Healthy
                living</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/aboriginal-health.html">Indigenous
                health</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/health-system-services.html">Health
                system and services</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/health/science-research-data.html">Science,
                research and data</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-health-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-health-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/industry-licensees-applicants/licensed-cultivators-processors-sellers.html">Licensed
                        cultivators, processors and seller of cannabis</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://healthycanadians.gc.ca/recall-alert-rappel-avis/index-eng.php">Food
                        and product recalls and safety alerts</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/health-canada/services/canada-food-guides.html">Canada's
                        food guide</a></li>
                </ul>
            </li>
        </ul>
    )
}
