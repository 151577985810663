export const EnvironmentAndNaturalResource = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-enviro" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement.html">Environnement<span
                    className="hidden-xs hidden-sm"> et ressources naturelles</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/meteo.html">Météo,
                    climat et catastrophes naturelles</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/energie.html">Énergie</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/ressources-naturelles.html">Ressources
                    naturelles</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://agriculture.canada.ca/fr/agriculture-environnement">Agriculture
                    et environnement</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/peches.html">Pêches</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/faune-flore-especes.html">Faune,
                    flore et espèces</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/pollution-gestion-dechets.html">Pollution
                    et gestion des déchets</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/environnement/conservation.html">Conservation
                    et protection de l'environnement</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-enviro-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-enviro-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://meteo.gc.ca/canada_f.html">Prévisions météo locales</a>
                        </li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.rncan.gc.ca/energie/efficacite/transports/20997">Véhicules
                            écoénergétiques</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.rncan.gc.ca/maisons">Efficacité
                            énergétique des maisons</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/environnement-changement-climatique/services/registre-public-especes-peril.html">Espèces
                            en péril</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/environnement-changement-climatique/services/meteo-saisonniere-dangereuse.html">Préparation
                            aux conditions météorologiques dangereuses</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-enviro" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment.html">Environment<span
                className="hidden-xs hidden-sm"> and natural resources</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/weather.html">Weather,
                climate and hazards</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/energy.html">Energy</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/natural-resources.html">Natural
                resources</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://agriculture.canada.ca/en/agriculture-and-environment">Agriculture
                and the environment</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/fisheries.html">Fisheries</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/wildlife-plants-species.html">Wildlife,
                plants and species</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/pollution-waste-management.html">Pollution
                and waste management</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/environment/conservation.html">Environmental
                conservation and protection</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-enviro-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-enviro-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://weather.gc.ca/canada_e.html">Local
                        weather forecast</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.nrcan.gc.ca/energy/efficiency/transportation/20996">Fuel-efficient
                        vehicles</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.nrcan.gc.ca/homes">Home
                        energy efficiency</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/environment-climate-change/services/species-risk-public-registry.html">Species
                        at risk</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/environment-climate-change/services/seasonal-weather-hazards.html">Prepare
                        for severe weather</a></li>
                </ul>
            </li>
        </ul>
    )
}
