import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ConfirmScheduledAppointment.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import ReturnTTPButton from "../Buttons/ReturnTTPButton/ReturnTTPButton";
import ClientInformation from "../ClientInformation/ClientInformation";
import { routeMappings } from "../../Router/UIRoutes";
import ReactToPrint from "react-to-print";
import {
  findAppointment,
  findEnrollmentCenterInformation,
} from "../ViewAppointment/ViewAppointmentUtil";
import { useSelector } from "react-redux";
import { findClient } from "../../Service/ClientService";
import { AppointmentDetails } from "../ViewAppointment/AppointmentDetails";

const ConfirmScheduledAppointment = () => {
  const { portWorklocations } = useSelector((state) => state.portWorklocations);
  const pdfRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [enrollmentCenter, setEnrollmentCenter] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [client, setClient] = useState(null);
  const [clientNotFound, setClientNotFound] = useState(false);
  const [client500, setClient500] = useState(false);
  const [hasAAppointment, setHasAAppointment] = useState(false);

  useEffect(() => {
    findClient(setClient, setClientNotFound, setClient500).catch((err) =>
      setClient500(true)
    );
  }, []);

  const doneHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    navigate("/home");
  };

  const rescheduleHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    navigate("/reschedule-appointment");
  };

  let title = t("appointmentConfirmed.title");
  if (location.pathname === routeMappings.rescheduleConfirm) {
    title = t("rescheduleAppointmentConfirmed.title");
  }

  useEffect(() => {
    if (portWorklocations && !appointment) {
      findClient(setClient, setClientNotFound, setClient500());
    }
  }, [portWorklocations]);

  useEffect(() => {
    if (client?.clientId) {
      // findAppointment()
      findAppointment(client.clientId, setAppointment, setClientNotFound);
    }
  }, [client]);

  useEffect(() => {
    if (appointment) {
      setEnrollmentCenter(
        findEnrollmentCenterInformation(appointment, portWorklocations)
      );
    }
  }, [appointment]);

  return (
    <>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      ></iframe>
      <div className={"mainContainer"} id={"printContent"} ref={pdfRef}>
        <div className="homeTitleContainer">
          <h1>{title}</h1>
        </div>
        <p>{t("appointmentConfirmed.paragraphOne")}</p>
        <br></br>
        <ClientInformation
          navBtn={
            <>
              <ReactToPrint
                content={() => pdfRef.current}
                trigger={() => (
                  <button className="buttonStyling" type={"submit"}>
                    {t("appointmentConfirmed.print")}
                  </button>
                )}
              />
              <button className="buttonStyling" onClick={doneHandler}>
                {t("appointmentConfirmed.done")}
              </button>
            </>
          }
          midInfo={
            <AppointmentDetails
              appointment={appointment}
              enrollmentCenter={enrollmentCenter}
            />
          }
        />
        <ReturnTTPButton />
      </div>
    </>
  );
};

export default ConfirmScheduledAppointment;
