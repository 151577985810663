import React, { useState } from "react";
import StartOverModal from "../../StartOverModal/StartOverModal";
import { useTranslation } from "react-i18next";

const StartOverButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const returnHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    setShowModal(true);
  };

  return (
    <div className="cancelContainer">
      <button className="startOverBtn" onClick={returnHandler}>
        {t("button.cancel")}
      </button>

      {showModal ? (
        <StartOverModal show={showModal} setShowModal={setShowModal} />
      ) : null}
    </div>
  );
};

export default StartOverButton;
