import { createSlice } from "@reduxjs/toolkit";

export const SetPortWorklocations = createSlice({
  name: "portWorklocations",
  initialState: {
    portWorklocations: [],
    programType: [],
    applicationType: [],
  },

  reducers: {
    portWorklocations: (state, action) => {
      state.portWorklocations = action.payload;
    },

    programType: (state, action) => {
      state.programType = action.payload;
    },

    applicationType: (state, action) => {
      state.applicationType = action.payload;
    },
  },
});

export const { portWorklocations, programType, applicationType } =
  SetPortWorklocations.actions;

export default SetPortWorklocations.reducer;
