import React from "react";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const CancelAppointmentButton = () => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const cancelHandler = () => {
    window.scrollTo({top:10,behavior:'smooth'});
    navigate("/cancel");
  };

  return (
    <button className="buttonStyling" onClick={cancelHandler}>
      {t('cancel.cancelBtn')}
    </button>
  );
};

export default CancelAppointmentButton;
