import { createSlice } from "@reduxjs/toolkit";

export const SetUserLanguage = createSlice({
  name: "userLocale",
  initialState: {
    userLocale: "en",
  },

  reducers: {
    currentLanguage: (state, action) => {
      state.userLocale = action.payload;
    },
  },
});

export const { currentLanguage } = SetUserLanguage.actions;

export default SetUserLanguage.reducer;
