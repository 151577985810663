import {getAppointmentDetails} from "../util/ApiCalls";

export const hasAppointment = async (clientId, setHasAAppointment) => {
    try {
        const applicant = await getAppointmentDetails(clientId);
        // applicant not found or found ?
        setHasAAppointment(applicant.status === 200 && applicant.data !== '')
    } catch (err) {
        console.log(err)
        setHasAAppointment(false)
    }
}
