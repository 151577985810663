export const CanadaAndTheWorld = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-canworld" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/index.aspx?lang=fra">Le Canada
                    et le monde<span className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/offices-bureaux/index.aspx?lang=fra">Bureaux
                    internationaux et contacts d’urgence</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/study_work_travel-etude_travail_voyage/index.aspx?lang=fra">Étude,
                    travail et voyage partout dans le monde</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/country-pays/index.aspx?lang=fra">Information
                    par pays et territoires</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/stories-histoires/index.aspx?lang=fra">Histoires</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/international_relations-relations_internationales/index.aspx?lang=fra">Relations
                    internationales</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/issues_development-enjeux_developpement/index.aspx?lang=fra">Enjeux
                    mondiaux et aide internationale</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://international.gc.ca/world-monde/funding-financement/index.aspx?lang=frag">Financement
                    d’initiatives internationales</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/entreprises/commerce/index.html">Commerce
                    international et investissement</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-canworld-sub"
                                           aria-expanded="true">En demande</a>
                    <ul id="gc-mnu-canworld-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.international.gc.ca/world-monde/covid-19/index.aspx?lang=fra">COVID-19
                            : Voyages, affaires étrangères, commerce et développement internationaux</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.educanada.ca/scholarships-bourses/non_can/index.aspx?lang=fra">Trouver
                            une bourse d’études canadienne en tant qu’étudiant international</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://treaty-accord.gc.ca/index.aspx?Lang=fra">Traités
                            internationaux signés par le Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://educanada.ca/index.aspx?lang=fra">Trouver des occasions
                            d’étude ou de recherche au Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://voyage.gc.ca/assistance/ambassades-consulats">Communiquer
                            avec une ambassade ou un consulat</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://international.gc.ca/protocol-protocole/reps.aspx?lang=fra">Communiquer
                            avec un représentant étranger au Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.international.gc.ca/gac-amc/about-a_propos/services/authentication-authentification/step-etape-1.aspx?lang=fra">Authentifier
                            un document</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-canworld" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/index.aspx?lang=eng">Canada and the
                world<span className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/offices-bureaux/index.aspx?lang=eng">International
                offices and emergency contacts</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/study_work_travel-etude_travail_voyage/index.aspx?lang=eng">Study,
                work and travel worldwide</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/country-pays/index.aspx?lang=eng">Information
                by countries and territories</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/stories-histoires/index.aspx?lang=eng">Stories</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/international_relations-relations_internationales/index.aspx?lang=eng">International
                relations</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/issues_development-enjeux_developpement/index.aspx?lang=eng">Global
                issues and international assistance</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://international.gc.ca/world-monde/funding-financement/index.aspx?lang=eng">Funding
                for international initiatives</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/business/trade/index.html">International
                trade and investment</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-canworld-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-canworld-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.international.gc.ca/world-monde/covid-19/index.aspx?lang=eng">COVID-19:
                        Trade, foreign affairs, international trade and development</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://educanada.ca/scholarships-bourses/non_can/index.aspx?lang=eng">Find
                        a Canadian scholarship as an international student</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://treaty-accord.gc.ca/index.aspx">International treaties
                        signed by Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://educanada.ca/index.aspx?lang=eng">Find international study
                        or research opportunities in Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://travel.gc.ca/assistance/embassies-consulates">Contact an
                        embassy or consulate</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://international.gc.ca/protocol-protocole/reps.aspx?lang=eng">Contact
                        a foreign representative in Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.international.gc.ca/gac-amc/about-a_propos/services/authentication-authentification/step-etape-1.aspx?lang=eng">Authenticate
                        a document</a></li>
                </ul>
            </li>
        </ul>
    )
}
