export const dateStringToDate = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  try {
    const datePieces = dateStr.split("-");
    return new Date(`${datePieces[1]}/${datePieces[2]}/${datePieces[0]}`);
  } catch (err) {
    return null;
  }
};

const pad = (num) => (num < 10 ? `0${num}` : num);

export const dateString = (date) => {
  if (date) {
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate())
    );
  }
};

export const trimDate = (isoDate) => {
  if (isoDate) return isoDate.split(" ")[0];
};

export const trimTime = (time) => {
  if (time) {
    const timeSections = time.split(":");
    return [timeSections[0], timeSections[1]].join(":");
  }
};

export const trimTDate = (isoDate) => {
  if (isoDate) return isoDate.split("T")[0];
};

export const getDate = (date, locale) => {
  if(date === undefined || locale === null){ return ;}
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  if (date!==undefined) {
    return date?.toLocaleDateString(locale, options);
  }
};

export const getHour = (time) => {
  if (!time) return;
  return time.split(":")[0];
};
