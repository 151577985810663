export const CultureHistoryAndSport = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-culture" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture.html">Culture<span
                    className="hidden-xs hidden-sm">, histoire et sport</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/patrimoine-canadien/services/financement.html">Financement
                    - Culture, histoire et sport</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/evenements-celebrations-commemorations.html">Événements,
                    célébrations et commémorations</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/attraits-culturels.html">Lieux
                    et attraits culturels</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/identite-canadienne-societe.html">Identité
                    canadienne et société</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/sport.html">Sport</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/histoire-patrimoine.html">Histoire
                    et patrimoine</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/arts-media.html">Arts et
                    média</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/programmes-culturels-jeunes.html">Programmes
                    culturels pour les jeunes</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/culture/commerce-investissement-culturels.html">Commerce
                    et investissement culturels</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-culture-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-culture-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.veterans.gc.ca/fra/remembrance/memorials/canadian-virtual-war-memorial">Visitez
                            le Mémorial virtuel de guerre du Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/culture/identite-canadienne-societe/hymnes-symboles.html">Hymnes
                            et symboles du Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://crtc.gc.ca/fra/8045/d2018.htm">Trouvez une décision du
                            CRTC</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://bibliotheque-archives.canada.ca//fra/collection/aide-recherche/genealogie-histoire-famille/Pages/genealogie-histoire-famille.aspx">Faites
                            des recherches sur votre histoire familiale</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.bac-lac.gc.ca/fra/recensements/Pages/recensements.aspx">Cherchez
                            des documents de recensement</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/culture/attraits-culturels/attraits-capitale-canada.html">Lieux
                            et attraits dans la capitale du Canada</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-culture" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture.html">Culture<span
                className="hidden-xs hidden-sm">, history and sport</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/canadian-heritage/services/funding.html">Funding -
                Culture, history and sport</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/events-celebrations-commemorations.html">Events,
                celebrations and commemorations</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/cultural-attractions.html">Cultural
                landmarks and attractions</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/canadian-identity-society.html">Canadian
                identity and society</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/sport.html">Sport</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/history-heritage.html">History
                and heritage</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/arts-media.html">Arts and
                media</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/cultural-youth-programs.html">Cultural
                youth programs</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/culture/cultural-trade-investment.html">Cultural
                trade and investment</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-culture-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-culture-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.veterans.gc.ca/eng/remembrance/memorials/canadian-virtual-war-memorial">Visit
                        the Canadian Virtual War Memorial</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/culture/canadian-identity-society/anthems-symbols.html">Anthems
                        and symbols of Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://crtc.gc.ca/eng/8045/d2018.htm">Find a CRTC decision</a>
                    </li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://library-archives.canada.ca/eng/collection/research-help/genealogy-family-history/pages/genealogy-family-history.aspx">Research
                        your family history</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.bac-lac.gc.ca/eng/census/Pages/census.aspx">Search
                        census records</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/services/culture/cultural-attractions/attractions-canada-capital.html">Landmarks
                        and attractions in Canada's capital</a></li>
                </ul>
            </li>
        </ul>
    )
}
