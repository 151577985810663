import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CancelAppointment.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { cancelAppointment } from "../../util/ApiCalls";
import {
  findAppointment,
  findEnrollmentCenterInformation,
} from "../ViewAppointment/ViewAppointmentUtil";
import { trimTime } from "../../Coverters/dateConverters";
import { codeDescription, convertCode } from "../../util/Function/CodeUtil";
import { findClient } from "../../Service/ClientService";
import { ErrorHandler } from "../ErrorHandler/ErrorHandler";

const CancelAppointment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { portWorklocations, programType, applicationType } = useSelector(
    (state) => state.portWorklocations
  );
  const [client, setClient] = useState(null);
  const [enrollmentCenter, setEnrollmentCenter] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [clientNotFound, setClientNotFound] = useState(null);
  const [client500, setClient500] = useState(null);

  useEffect(() => {
    if (portWorklocations) {
      findClient(setClient, setClientNotFound, setClient500);
    }
  }, [portWorklocations]);

  useEffect(() => {
    if (client?.clientId) {
      findAppointment(client.clientId, setAppointment, setClientNotFound);
    }
  }, [client]);

  useEffect(() => {
    if (appointment) {
      setEnrollmentCenter(
        findEnrollmentCenterInformation(appointment, portWorklocations)
      );
    }
  }, [appointment]);

  const cancelButtonHandler = () => {
    navigate("/home");
    window.scrollTo({top:10,behavior:'smooth'});
  };

  const cancelAppointmentResults = async () => {
    window.scrollTo({top:10,behavior:'smooth'});
    let results = await cancelAppointment(client.clientId, appointment.id);
    if (results.status === 200) {
      navigate("/cancelled-appointment");
    }
    if (results.status === 500) {
      console.log("error");
    }
  };
  const confirmCancelHandler = () => {
    cancelAppointmentResults();
    window.scrollTo({top:10,behavior:'smooth'});
  };

  return (
    <>
      <div id={"wb-cont"} className="mainContainer">
        <div className="homeTitleContainer">
          <h1>{t("cancel.title")}</h1>
        </div>
        <div>
          <strong>
            <p>{t("cancel.cancelDescriptionText")}</p>
          </strong>
          <strong>
            <p>{t("cancel.cancelDescriptionTextConfirm")}</p>
          </strong>
        </div>
        <div>
          <div className="pageContainer">
            {clientNotFound ? (
              <ErrorHandler is404={clientNotFound} is500={client500} />
            ) : (
              <div className="textContainer">
                <p>
                  <b>{t("cancel.intDate")} </b> {appointment?.date}
                </p>

                <p>
                  <b>{t("cancel.intTime")} </b> {trimTime(appointment?.time)}
                </p>

                <p>
                  <b>{t("cancel.enrollCenter")} </b>{" "}
                  {codeDescription(enrollmentCenter)}
                </p>

                <p>
                  <b>{t("cancel.applicantProgram")} </b>{" "}
                  {convertCode(client?.applicationProgram, applicationType)}
                </p>

                <p>
                  <b>{t("cancel.applicantType")} </b>{" "}
                  {convertCode(client?.applicationType, programType)}
                </p>

                <br />
                <div className="cancel-btn-container">
                  <button
                    className="buttonStyling"
                    onClick={confirmCancelHandler}
                  >
                    {t("cancel.cancelBtn")}
                  </button>
                  <button
                    className="startOverBtn"
                    onClick={cancelButtonHandler}
                  >
                    {t("button.back")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelAppointment;
