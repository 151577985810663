export const findParam = (code, paramKey) => {
    if (code && code.parameters) {
        for (const p of code.parameters) {
            
            if (p.key === paramKey) {
                return p.value
            }
        }
    }
}

export const getDirections=(enrollmentCenter,locale)=>{
    let data = findParam(enrollmentCenter,"data");
    
    if(data){
        data =  JSON.parse(data)
        
        for (let param of Object.keys(data)){
            
            if (locale ==="en"){
                return data.en.directions;
                
            }else if (locale ==="fr"){
                return data.fr.directions;
            }
    
        }
    }
    
    

}
