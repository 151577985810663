export const ImmigrationAncCitizenship = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-cit" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/immigration-citoyennete.html">Immigration<span
                    className="hidden-xs hidden-sm"> et citoyenneté</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/demande.html">Ma
                    demande</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/visiter-canada.html">Visiter</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/immigrer-canada.html">Immigrer</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/travailler-canada.html">Travailler</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/etudier-canada.html">Étudier</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/citoyennete-canadienne.html">Citoyenneté</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/nouveaux-immigrants.html">Nouveaux
                    immigrants</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/canadiens.html">Canadiens</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/refugies.html">Réfugiés
                    et octroi de l’asile</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/immigration-citoyennete/application-loi-infractions.html">Application
                    de la loi et infractions</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-cit-sub" aria-expanded="true">En
                    demande</a>
                    <ul id="gc-mnu-cit-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/demande/compte.html">Se
                            connecter ou créer un compte pour présenter une demande en ligne</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/demande/verifier-etat.html">Vérifier
                            l’état de sa demande</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cic.gc.ca/francais/information/delais/index.asp">Vérifier
                            les délais de traitement des demandes</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/demande/formulaires-demande-guides.html">Trouver
                            un formulaire de demande</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cic.gc.ca/francais/information/frais/index.asp">Payer
                            les frais</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cic.gc.ca/francais/visiter/visas.asp">Déterminer si
                            vous avez besoin d’une AVE ou d’un visa pour visiter le Canada</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.cic.gc.ca/francais/centre-aide/index-en-vedette-can.asp">Trouver
                            réponse à ses questions dans le Centre d’aide</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-cit" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/immigration-citizenship.html">Immigration<span
                className="hidden-xs hidden-sm"> and citizenship</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application.html">My
                application</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada.html">Visit</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada.html">Immigrate</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada.html">Work</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada.html">Study</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-citizenship.html">Citizenship</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants.html">New
                immigrants</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/canadians.html">Canadians</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees.html">Refugees
                and asylum</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/immigration-citizenship/enforcement-violations.html">Enforcement
                and violations</a></li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-cit-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-cit-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/account.html">Sign
                        in or create an account to apply online</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-status.html">Check
                        your application status</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html">Check
                        application processing times</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides.html">Find
                        an application form</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cic.gc.ca/english/information/fees/index.asp">Pay your
                        fees</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cic.gc.ca/english/visit/visas.asp">Find out if you need
                        an eTA or a visa to visit Canada</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.cic.gc.ca/english/helpcentre/index-featured-can.asp">Have
                        questions? Find answers in the Help Centre</a></li>
                </ul>
            </li>
        </ul>
    )
}
