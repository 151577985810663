export const TransportAndInfrastructure = ({ showing, en }) => {
    if (!showing) return
    if (en === 'fr') {
        return (
            <ul id="gc-mnu-policing" role="menu" aria-orientation="vertical">
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police.html">Services de police<span
                    className="hidden-xs hidden-sm">, justice et urgences</span><span
                    className="visible-xs-inline visible-sm-inline"> : accueil</span></a></li>
                <li role="separator"></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/servicespolice.html">Services
                    de police</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/justice.html">Justice</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/urgences.html">Urgences</a>
                </li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/correctionnels.html">Services
                    correctionnels</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/liberationconditionnelle.html">Libération
                    conditionnelle, suspension du casier, radiation et clémence</a></li>
                <li role="presentation"><a role="menuitem" tabIndex="-1"
                                           href="https://www.canada.ca/fr/services/police/victimes.html">Victimes
                    d'actes criminels</a></li>
                <li role="separator" aria-orientation="horizontal"></li>
                <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                           aria-haspopup="true" aria-controls="gc-mnu-policing-sub"
                                           aria-expanded="true">En demande</a>
                    <ul id="gc-mnu-policing-sub" role="menu" aria-orientation="vertical">
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.rcmp-grc.gc.ca/cfp-pcaf/online_en-ligne/index-fra.htm">Demander
                            ou renouveler un permis d'arme à feu</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.rcmp-grc.gc.ca/fr/verification-casier-judiciaire">Obtenir
                            une attestation de vérification de casier judiciaire</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/commission-liberations-conditionnelles/services/suspension-du-casier/guide-et-formulaires-de-demande.html">Demander
                            la suspension d’un casier judiciaire</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.preparez-vous.gc.ca/cnt/hzd/drng-fr.aspx">Que faire
                            durant une urgence</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/police/servicespolice/securite-communautaire-police/conduite-facultes-affaiblies.html">Connaissez
                            la loi sur la conduite avec facultés affaiblies</a></li>
                        <li role="presentation"><a role="menuitem" tabIndex="-1"
                                                   href="https://www.canada.ca/fr/services/police/servicespolice/aider-resoudre-un-crime.html">Aidez
                            à résoudre un crime</a></li>
                    </ul>
                </li>
            </ul>
        )
    }
    return (
        <ul id="gc-mnu-trans" role="menu" aria-orientation="vertical">
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.canada.ca/en/services/transport.html">Transport<span
                className="hidden-xs hidden-sm"> and infrastructure</span><span
                className="visible-xs-inline visible-sm-inline">: home</span></a></li>
            <li role="separator"></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.tc.gc.ca/en/services/aviation.html">Aviation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.tc.gc.ca/en/services/marine.html">Marine
                transportation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.tc.gc.ca/en/services/road.html">Road
                transportation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1" href="https://www.tc.gc.ca/en/services/rail.html">Rail
                transportation</a></li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.tc.gc.ca/en/services/dangerous-goods.html">Dangerous goods</a>
            </li>
            <li role="presentation"><a role="menuitem" tabIndex="-1"
                                       href="https://www.tc.gc.ca/en/services/infrastructure.html">Infrastructure</a>
            </li>
            <li role="separator" aria-orientation="horizontal"></li>
            <li role="presentation"><a data-keep-expanded="md-min" href="#" role="menuitem" tabIndex="-1"
                                       aria-haspopup="true" aria-controls="gc-mnu-trans-sub" aria-expanded="true">Most
                requested</a>
                <ul id="gc-mnu-trans-sub" role="menu" aria-orientation="vertical">
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tc.gc.ca/en/services/aviation/drone-safety.html">Drone
                        safety</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://tc.canada.ca/en/aviation/aviation-security/what-not-bring-plane">What
                        you can't bring on an airplane</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tc.gc.ca/eng/marinesafety/oep-vesselreg-menu-728.htm">Register
                        your vessel</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tc.gc.ca/en/services/road/child-car-seat-safety.html">Child
                        car seat safety</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tc.gc.ca/eng/tdg/clear-tofc-211.htm">Transporting
                        dangerous goods - Regulations</a></li>
                    <li role="presentation"><a role="menuitem" tabIndex="-1"
                                               href="https://www.tc.gc.ca/eng/acts-regulations/regulations-sor96-433.htm">Canadian
                        Aviation Regulations</a></li>
                </ul>
            </li>
        </ul>
    )
}
